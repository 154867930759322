import styled from 'styled-components';

export const Content = styled.div`
  text-align: center;
  font-size: 26px;
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 30px;
`;
