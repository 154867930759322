import { useState } from 'react';
import { AxiosError } from 'axios';
import { ActionButton } from '../../components/action-button';
import { useAuthDispatch } from '../../providers/auth/contexts';
import { AUTH_ACTION_TYPES } from '../../providers/auth/actions-types';
import { useLogin } from '../../queries/auth';
import { CustomInput, ErrorMessage, Logo, PageForm, PageWrapper } from './style';

export const LoginScreen = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const authDispatch = useAuthDispatch();

  const { mutate: mutateLogin, isLoading } = useLogin();

  const handleSuccessLogin = () => {
    authDispatch({
      type: AUTH_ACTION_TYPES.SUCCESS_LOGIN,
    });
  };

  const handleFailedLogin = (error: AxiosError) => {
    switch (error.response?.status) {
      case 401: {
        setErrorMessage('שם משתמש או סיסמה שגויים');
        break;
      }
      default: {
        setErrorMessage('ההתחברות נכשלה');
        break;
      }
    }
  };

  const handleLoginClick = () => {
    if (!username || !password) {
      setErrorMessage('חובה להזין שם משתמש וסיסמה');

      return;
    }

    setErrorMessage('');

    mutateLogin(
      { username: username.trim(), password: password.trim() },
      { onSuccess: handleSuccessLogin, onError: handleFailedLogin },
    );
  };

  // const handleGuestLoginButton = () => {
  //   setErrorMessage('');

  //   mutateLogin(
  //     {
  //       username: 'guest',
  //       password: 'guest',
  //     },
  //     {
  //       onSuccess: handleSuccessLogin,
  //       onError: handleFailedLogin,
  //     },
  //   );
  // };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.currentTarget.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const firstUsernameChar = username.charAt(0);
  const isHebrewUsername = firstUsernameChar >= 'א' && firstUsernameChar <= 'ת';

  const firstPasswordChar = password.charAt(0);
  const isHebrewPassword = firstPasswordChar >= 'א' && firstPasswordChar <= 'ת';

  return (
    <PageWrapper>
      <PageForm
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleLoginClick();
        }}
      >
        <Logo />

        {/* <ActionButton title="כניסה" onClick={handleGuestLoginButton} disabled={isLoading} />

        <Separator /> */}

        <CustomInput
          type="text"
          label="שם משתמש"
          value={username}
          direction={isHebrewUsername ? 'rtl' : 'ltr'}
          onChange={handleUsernameChange}
        />

        <CustomInput
          type="password"
          label="סיסמה"
          value={password}
          direction={isHebrewPassword ? 'rtl' : 'ltr'}
          onChange={handlePasswordChange}
        />

        {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

        <ActionButton title="כניסה" disabled={isLoading} type="submit" />
      </PageForm>
    </PageWrapper>
  );
};
