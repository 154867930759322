import styled from 'styled-components';
import { COLORS } from '../../../../consts/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid ${COLORS.EXTRA_LIGHT_GRAY};

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const Title = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 24px;
`;
