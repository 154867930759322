import { Address } from "../../@types/address";
import { AddressResponse, formatAddresses } from "./addresses";

export type GetDefaultRouteServerResponse = {
  addresses: AddressResponse[];
  id: string;
  name: string;
};

export type GetDefaultRouteResponse = {
  addresses: Address[];
  id: string;
  name: string;
};

export const formatDefaultRoute = (response: GetDefaultRouteServerResponse):
  GetDefaultRouteResponse => {
  return {
    ...response,
    addresses: formatAddresses(response.addresses),
  };
};
