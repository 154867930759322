import { Header } from '../../components/header';
import { FAB_BOTTOM_SPACING } from '../../components/share-fab';
import { useUsers } from '../../queries/users';
import { UsersList } from './users-list';

export const UsersScreen = () => {
  const { isFetching, data: users } = useUsers();

  return (
    <>
      <Header screenTitle="משתמשים" hasBack isLoading={isFetching} />
      <div style={{ width: '95%', margin: '10px auto 0 auto', paddingBottom: FAB_BOTTOM_SPACING }}>
        <UsersList
          users={users || []}
          onUserClick={() => {
            // alert(user);
          }}
        />
      </div>
    </>
  );
};
