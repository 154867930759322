import { APARTMENT_STATUS, Address } from '../../../@types/address';
import { CloseIcon, DoneIcon, LeftArrowIcon, PackageIcon } from '../../../assets/svgs';
import { COLORS } from '../../../consts/colors';
import { NewBadge, PackagesBadge } from '../../badges';
import { CurrentIcon, ItemCounter, NotOpenedIcon, OpenedIcon, Title, Wrapper } from './style';

type AddressListItemProps = {
  itemCounter: number;
  address: Address;
  isCurrent?: boolean;
  onClick: () => void;
};

export const AddressListItem = ({
  itemCounter,
  address,
  onClick,
  isCurrent = false,
}: AddressListItemProps) => {
  const {
    street,
    buildingNumber,
    totalPackages,
    status,
    entrance,
    apartmentNumber,
    wasPackageLeft,
  } = address;

  const buildingEntrance = entrance ?? '';
  const apartment = apartmentNumber ? `(דירה ${apartmentNumber})` : '';

  const title = `${street} ${buildingNumber} ${buildingEntrance} ${apartment}`;

  const renderAddressStatusIcon = () => {
    if (isCurrent) {
      return (
        <CurrentIcon>
          <LeftArrowIcon style={{ fill: COLORS.WHITE }} width={20} height={20} />
        </CurrentIcon>
      );
    }

    switch (status) {
      case APARTMENT_STATUS.OPENED: {
        return (
          <OpenedIcon>
            <DoneIcon style={{ fill: COLORS.DARK_BLUE }} width={23} height={23} />
          </OpenedIcon>
        );
      }
      case APARTMENT_STATUS.NOT_OPENED: {
        if (wasPackageLeft) {
          return (
            <NotOpenedIcon>
              <PackageIcon style={{ fill: COLORS.DARK_BLUE }} width={23} height={23} />
            </NotOpenedIcon>
          );
        }

        return (
          <NotOpenedIcon>
            <CloseIcon style={{ fill: COLORS.DARK_BLUE }} width={20} height={20} />
          </NotOpenedIcon>
        );
      }
      default: {
        return <ItemCounter>{itemCounter}</ItemCounter>;
      }
    }
  };

  const renderPackagesCounter = () => {
    if (totalPackages <= 1) {
      return null;
    }

    return <PackagesBadge>{totalPackages} אריזות</PackagesBadge>;
  };

  return (
    <Wrapper onClick={onClick} done={!!status}>
      {renderAddressStatusIcon()}
      <Title isCurrent={isCurrent}>{title}</Title>
      {address._isNewAddress && <NewBadge>חדש</NewBadge>}
      {renderPackagesCounter()}
    </Wrapper>
  );
};
