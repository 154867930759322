import { Address, ExtendedAddress } from '../../../@types/address';
import { AddressListItem } from './address-list-item';

type AddressesListProps = {
  addresses: ExtendedAddress[];
  onAddressClick: (address: Address) => void;
};

export const AddressesList = ({ addresses, onAddressClick }: AddressesListProps) => {
  if (addresses.length <= 0) {
    return <div>אין כתובות להצגה</div>;
  }

  return (
    <>
      {addresses.map((address) => {
        return (
          <AddressListItem
            key={address.id}
            address={address}
            onClick={() => {
              onAddressClick(address);
            }}
          />
        );
      })}
    </>
  );
};
