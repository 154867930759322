import { useNavigate } from 'react-router-dom';
import { Header, HeaderButton } from '../../components/header';
import { useDistributions } from '../../queries/distributions';
import { useAuthPermissions } from '../../hooks/auth';
import { PERMISSION } from '../../consts/permissions';
import { EditIcon } from '../../assets/svgs';
import { SCREENS } from '../../consts/screens';
import { Distribution } from '../../@types/distributions';
import { DistributionsList } from './distributions-list';

export const DistributionsScreen = () => {
  const { isFetching, data: distributions = [] } = useDistributions();

  const navigate = useNavigate();
  const { hasPermissions } = useAuthPermissions();

  // if (distributions.length === 1) {
  //   const item = distributions[0];

  //   return <Navigate to={`/distribution/${item.id}/routes`} replace={true} />;
  // }

  const handleEditDistributionsClick = () => {
    navigate(SCREENS.EDIT_DISTRIBUTIONS);
  };

  const handleDistributionClick = (distribution: Distribution) => {
    navigate(`/distribution/${distribution.id}/routes`);
  };

  const defineHeaderButtons = () => {
    const buttons: HeaderButton[] = [];

    if (hasPermissions([PERMISSION.EDIT_DISTRIBUTIONS])) {
      buttons.push({ title: 'עריכה', icon: <EditIcon />, onclick: handleEditDistributionsClick });
    }

    return buttons;
  };

  return (
    <>
      <Header
        screenTitle="חלוקות פעילות"
        hasBack
        isLoading={isFetching}
        buttons={defineHeaderButtons()}
      />
      <div style={{ width: '95%', margin: '10px auto 0 auto' }}>
        <DistributionsList
          distributions={distributions.filter((distribution) => distribution.isActive)}
          onDistributionClick={handleDistributionClick}
        />
      </div>
    </>
  );
};
