import axios, { AxiosResponse, isAxiosError } from 'axios';
import { CONFIG } from '../config';
import { HTTP_RESPONSE_CODE } from '../consts/http';
import { SCREENS } from '../consts/screens';
import { ENDPOINTS } from './endpoints';

export const axiosInstance = axios.create({
  baseURL: CONFIG.BASE_URL,
  timeout: CONFIG.API_TIMEOUT,
  withCredentials: true,
});

const handleSuccessResponse = (response: AxiosResponse) => {
  return response;
};

const handleErrorResponse = (error: Error) => {
  if (isAxiosError(error)) {
    switch (error.response?.status) {
      case HTTP_RESPONSE_CODE.UNAUTHORIZED: {
        const { url } = error.response.config;
        if (url !== ENDPOINTS.ME && url !== ENDPOINTS.LOGIN) {
          // reload to page to make the user re-login
          window.location.reload();
        }
        break;
      }
      case HTTP_RESPONSE_CODE.FORBIDDEN: {
        window.location.replace(SCREENS.HOME);
      }
    }
  }

  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(handleSuccessResponse, handleErrorResponse);
