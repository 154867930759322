import styled from 'styled-components';
import { COLORS } from '../../../consts/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
  align-items: center;
  position: relative;

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const StatusIcon = styled.div`
  display: flex;
  width: 35px;
  height: 35px;
  border: 1px solid ${COLORS.DARK_BLUE};
  background-color: ${COLORS.WHITE};
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  color: ${COLORS.DARK_BLUE};
  font-size: 30px;
  margin-left: 20px;
`;

export const Content = styled.div`
  flex: 1;
`;

export const DistributionName = styled.div`
  font-weight: 400;
  line-height: 18px;
  color: ${COLORS.DARK_BLUE};
`;

export const DistributionDate = styled.div`
  color: ${COLORS.DARKER_GRAY};
`;
