import { DoneIcon } from '../../assets/svgs';
import { COLORS } from '../../consts/colors';
import { Box } from './style';

type CheckboxProps = {
  isChecked: boolean;
  onToggleCheck?: () => void;
};

export const Checkbox = ({ isChecked, onToggleCheck }: CheckboxProps) => {
  return (
    <Box
      isChecked={isChecked}
      onClick={(event) => {
        if (typeof onToggleCheck === 'function') {
          event.stopPropagation();
          onToggleCheck();
        }
      }}
    >
      <DoneIcon style={{ fill: COLORS.WHITE }} width={23} height={23} />
    </Box>
  );
};
