import { DefaultRoute } from '../../../@types/default-routes';
import { EditableRouteListItem } from './editable-route-list-item';

type RoutesListProps = {
  routes: DefaultRoute[];
  onRouteClick: (route: DefaultRoute) => void;
  onToggleRouteSelect: (route: DefaultRoute) => void;
  onEditRouteClick: (route: DefaultRoute) => void;
  selectedRoutes?: string[];
};

export const RoutesList = ({
  routes,
  onRouteClick,
  selectedRoutes,
  onToggleRouteSelect,
  onEditRouteClick,
}: RoutesListProps) => {
  if (routes.length <= 0) {
    return <div>אין מסלולים</div>;
  }

  return (
    <>
      {routes.map((route) => {
        return (
          <EditableRouteListItem
            onClick={() => {
              onRouteClick(route);
            }}
            key={route.id}
            isSelected={selectedRoutes?.includes(route.id) || false}
            route={route}
            onToggleSelect={() => {
              onToggleRouteSelect(route);
            }}
            onEditClick={() => {
              onEditRouteClick(route);
            }}
          />
        );
      })}
    </>
  );
};
