import { APARTMENT_STATUS, AddressHistoryRecord } from '../../../@types/address';
import { CloseIcon, DoneIcon, PackageIcon, QuestionMarkIcon } from '../../../assets/svgs';
import { COLORS } from '../../../consts/colors';
import { Content, DistributionDate, DistributionName, StatusIcon, Wrapper } from './style';

type HistoryRecordItemProps = {
  record: AddressHistoryRecord;
};

export const HistoryRecordItem = ({ record }: HistoryRecordItemProps) => {
  const { distributionName, date, inDistribution, status, wasPackageLeft } = record;

  const renderAddressStatusIcon = () => {
    if (!inDistribution) {
      return <StatusIcon></StatusIcon>;
    }

    switch (status) {
      case APARTMENT_STATUS.OPENED: {
        return (
          <StatusIcon>
            <DoneIcon style={{ fill: COLORS.DARK_BLUE }} width={23} height={23} />
          </StatusIcon>
        );
      }
      case APARTMENT_STATUS.NOT_OPENED: {
        if (wasPackageLeft) {
          return (
            <StatusIcon>
              <PackageIcon style={{ fill: COLORS.DARK_BLUE }} width={23} height={23} />
            </StatusIcon>
          );
        }

        return (
          <StatusIcon>
            <CloseIcon style={{ fill: COLORS.DARK_BLUE }} width={20} height={20} />
          </StatusIcon>
        );
      }
      default: {
        return (
          <StatusIcon>
            <QuestionMarkIcon style={{ fill: COLORS.DARK_BLUE }} width={22} height={22} />
          </StatusIcon>
        );
      }
    }
  };

  return (
    <Wrapper>
      {renderAddressStatusIcon()}
      <Content>
        <DistributionName>{distributionName}</DistributionName>
        <DistributionDate>{`${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()}`}</DistributionDate>
      </Content>
    </Wrapper>
  );
};
