import { ActionButtonType, Button } from './style';

type ActionButtonProps = {
  title: string;
  kind?: ActionButtonType;
  type?: 'submit' | 'reset' | 'button';
  onClick?: () => void; // may be undefined if type is `submit` (inside a form)
  disabled?: boolean;
};

export const ActionButton = ({
  title,
  kind = 'primary',
  type = 'button',
  onClick,
  disabled = false,
}: ActionButtonProps) => {
  return (
    <Button value={title} buttonType={kind} onClick={onClick} disabled={disabled} type={type}>
      {title}
    </Button>
  );
};
