import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Header, HeaderButton } from '../../components/header';
import { useRoute, useSetRouteOrder } from '../../queries/route';
import { AddressesList } from '../../components/addresses-list';
import { Address } from '../../@types/address';
import { SplitIcon } from '../../assets/svgs';
import { useMoveAddressesToNewRoute } from '../../queries/address';
import { AlertModal } from '../../components/alert-modal';
import { PromptModal } from '../../components/prompt-modal';
import { AddFAB, FAB_BOTTOM_SPACING } from '../../components/add-fab';
import { RouteNotActiveWarning } from '../../components/not-active-warning';
import { LinkAddressesModal } from '../../components/link-addresses-modal';
import { useLinkAddressesToRoute } from '../../queries/routes';

export const RouteEditorScreen = () => {
  const { routeId = '', distributionId = '' } = useParams();

  const [selectedAddresses, setSelectedAddresses] = useState<string[]>([]);
  const [isPromptSplitRouteModalVisible, setPromptSplitRouteModalVisibility] = useState(false);
  const [alertMessage, setAlertMessage] = useState<null | string>(null);
  const [isLinkAddressesModalVisible, setLinkAddressesModalVisibility] = useState(false);

  const {
    isFetching,
    data: route,
    refetch: refetchRouteList,
    isLoading: isFirstLoad,
  } = useRoute(routeId);
  const { mutate: mutateRouteOrder, isLoading: isUpdating } = useSetRouteOrder(routeId);
  const { mutate: mutateMoveAddressesToNewRoute, isLoading: isMoving } =
    useMoveAddressesToNewRoute();
  const { mutate: mutateLinkAddressesToRoute, isLoading: isLinking } =
    useLinkAddressesToRoute(routeId);

  const isLoading = isFetching || isUpdating || isMoving || isLinking;

  const toggleAddressSelect = (address: Address) => {
    const newSelectedAddresses = [...selectedAddresses];

    const itemIndex = newSelectedAddresses.findIndex((selectedItem) => {
      return selectedItem === address.id;
    });

    if (itemIndex >= 0) {
      newSelectedAddresses.splice(itemIndex, 1);
    } else {
      newSelectedAddresses.push(address.id);
    }

    setSelectedAddresses(newSelectedAddresses);
  };

  const handleRouteOrderChanged = (newAddressesOrder: string[]) => {
    mutateRouteOrder(
      {
        addresses: newAddressesOrder,
      },
      {
        onSuccess: () => {
          refetchRouteList();
        },
      },
    );
  };

  const handleSplitClick = () => {
    if (selectedAddresses.length <= 0) {
      setAlertMessage('לא נבחרו כתובות');

      return;
    }

    if (selectedAddresses.length === route?.addresses?.length) {
      setAlertMessage('בחרת את כל הכתובות. אין סיבה לפצל');

      return;
    }

    setPromptSplitRouteModalVisibility(true);
  };

  const handleConfirmSplitAlertClick = () => {
    setAlertMessage(null);
  };

  const handleConfirmSplitClick = (newRouteName: string) => {
    setPromptSplitRouteModalVisibility(false);

    mutateMoveAddressesToNewRoute(
      {
        distributionId: distributionId,
        oldRouteId: routeId,
        newRouteName,
        AddressesList: selectedAddresses,
      },
      {
        onSuccess: () => {
          setSelectedAddresses([]);
          refetchRouteList();
        },
      },
    );
  };

  const handleCancelSplitClick = () => {
    setPromptSplitRouteModalVisibility(false);
  };

  const renderScreenTitle = () => {
    if (selectedAddresses.length > 0) {
      return `נבחרו: ${selectedAddresses.length}`;
    }

    return 'עריכת כתובות במסלול';
  };

  const renderActionButtons = (): HeaderButton[] | undefined => {
    if (selectedAddresses.length <= 0) {
      return;
    }

    return [{ title: 'פיצול', icon: <SplitIcon />, onclick: handleSplitClick }];
  };

  const handleLinkAddressesClick = () => {
    setLinkAddressesModalVisibility(true);
  };

  const handleAddAddressesSaveClick = (addressesIds: string[]) => {
    setLinkAddressesModalVisibility(false);
    mutateLinkAddressesToRoute(
      {
        addresses: addressesIds,
      },
      {
        onSuccess: () => {
          refetchRouteList();
        },
      },
    );
  };

  const handleAddAddressesCancelClick = () => {
    setLinkAddressesModalVisibility(false);
  };

  return (
    <>
      <Header
        screenTitle={renderScreenTitle()}
        hasBack
        isLoading={isLoading}
        buttons={renderActionButtons()}
      />
      <div style={{ width: '95%', margin: '10px auto 0 auto', paddingBottom: FAB_BOTTOM_SPACING }}>
        {!isFirstLoad && !route?.isActive && <RouteNotActiveWarning />}

        <AddFAB onClick={handleLinkAddressesClick} />

        <AddressesList
          isEditable
          addresses={route?.addresses ?? []}
          onAddressClick={toggleAddressSelect}
          selectedAddresses={selectedAddresses}
          onRouteOderChanged={handleRouteOrderChanged}
        />
      </div>

      {isPromptSplitRouteModalVisible && (
        <PromptModal
          title="פיצול מסלול"
          inputLabel="שם המסלול החדש"
          onSaveClick={handleConfirmSplitClick}
          onCancel={handleCancelSplitClick}
        />
      )}

      <AlertModal isVisible={!!alertMessage} onConfirm={handleConfirmSplitAlertClick}>
        {alertMessage}
      </AlertModal>

      {isLinkAddressesModalVisible && (
        <LinkAddressesModal
          filterAddresses={route?.addresses.map((address) => address.id)}
          onSave={handleAddAddressesSaveClick}
          onCancel={handleAddAddressesCancelClick}
        />
      )}
    </>
  );
};
