import styled from 'styled-components';
import { COLORS } from '../../../consts/colors';

export const ImageContainer = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  font-size: 0;
`;

export const BlessingMessage = styled.div`
  font-size: 56px;
  color: ${COLORS.DARK_BLUE};
  width: 100%;
  text-align: center;
`;

export const BackToAddresses = styled.div`
  margin-top: 20px;
  font-size: 24px;
  color: ${COLORS.DARK_BLUE};
  text-decoration: underline;
  cursor: pointer;
`;
