import { AUTH_ACTION_TYPES, AuthAction } from "./actions-types";
import { AuthState, LOGIN_STATE, defaultAuthState } from "./state";

export const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AUTH_ACTION_TYPES.SUCCESS_LOGIN: {
      return {
        ...state,
        loginState: LOGIN_STATE.POST_LOGIN,
      };
    }
    case AUTH_ACTION_TYPES.LOADED_USER_INFO: {
      return {
        ...state,
        loginState: LOGIN_STATE.LOGGED_IN,
        permissions: action.payload.permissions,
      };
    }
    case AUTH_ACTION_TYPES.LOGOUT: {
      // remove all values and reset the state
      return {
        ...defaultAuthState,
        loginState: LOGIN_STATE.LOGGED_OUT,
      };
    }
    default: {
      return state;
    }
  }
};
