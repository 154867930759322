import styled from 'styled-components';
import { COLORS } from '../../consts/colors';

export const HistoryList = styled.div`
  position: relative;
`;

// 20px + 20px = padding top & bottom of modal content
export const HistoryLine = styled.div`
  content: '';
  display: block;
  width: 2px;
  height: calc(100% + 20px + 20px);
  background-color: ${COLORS.DARK_BLUE};
  position: absolute;
  top: -20px;
  right: 17px;
`;
