import { Header } from '../../components/header';
import { PERMISSION } from '../../consts/permissions';
import { useAuthPermissions } from '../../hooks/auth';
import { NotOpenedAddressesStatistics } from './not-opened-addresses';

export const StatisticsScreen = () => {
  const { hasPermissions } = useAuthPermissions();

  return (
    <>
      <Header screenTitle="סטטיסטיקות" hasBack />
      <div
        style={{
          width: '95%',
          margin: '10px auto 0 auto',
        }}
      >
        {hasPermissions([PERMISSION.VIEW_ADDRESSES_HISTORY]) && <NotOpenedAddressesStatistics />}
      </div>
    </>
  );
};
