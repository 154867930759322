import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SCREENS } from '../../consts/screens';
import { useAuth, useAuthDispatch } from '../../providers/auth/contexts';
import { LoginScreen } from '../login';
import { LOGIN_STATE } from '../../providers/auth/state';
import { ActionButton } from '../../components/action-button';
import { Header } from '../../components/header';
import { FAB_BOTTOM_SPACING, ShareFAB } from '../../components/share-fab';
import { useLogout } from '../../queries/auth';
import { AUTH_ACTION_TYPES } from '../../providers/auth/actions-types';
import { useAuthPermissions } from '../../hooks/auth';
import { PERMISSION } from '../../consts/permissions';
import { CONFIG } from '../../config';
import { ActionLink } from '../../components/action-link';
import { Modal } from '../../components/modal';
import { IMAGES } from '../../assets/images';
import { NewBadge } from './style';

export const HomeScreen = () => {
  const auth = useAuth();
  const authDispatch = useAuthDispatch();
  const navigate = useNavigate();

  const [isQRModalVisible, setQRModalVisibility] = useState(false);

  const { hasPermissions } = useAuthPermissions();

  const { mutate: mutateLogout, isLoading: isLoggingOut } = useLogout();

  const handleDistributionsClick = () => {
    navigate(SCREENS.DISTRIBUTIONS);
  };

  const handleAllAddressesClick = () => {
    navigate(SCREENS.ALL_ADDRESSES);
  };

  const handleStatisticsClick = () => {
    navigate(SCREENS.STATISTICS);
  };

  const handleManageUsersClick = () => {
    navigate(SCREENS.USERS);
  };

  const handleDefaultRoutesClick = () => {
    navigate(SCREENS.DEFAULT_ROUTES);
  };

  const handleShowQRClick = () => {
    setQRModalVisibility(true);
  };

  const handleCloseQRClick = () => {
    setQRModalVisibility(false);
  };

  const handleLogoutClick = () => {
    mutateLogout(
      {},
      {
        onSuccess: () => {
          authDispatch({
            type: AUTH_ACTION_TYPES.LOGOUT,
          });
        },
      },
    );
  };

  if (auth.loginState !== LOGIN_STATE.LOGGED_IN) {
    return <LoginScreen />;
  }

  return (
    <>
      <Header screenTitle="ניווט" isLoading={isLoggingOut} />
      <div
        style={{
          width: '95%',
          margin: '10px auto 0 auto',
          paddingBottom: FAB_BOTTOM_SPACING,
          paddingTop: 30,
        }}
      >
        <ShareFAB message='היי, הנה הקישור למערכת החלוקה של סח"י:' />

        <div style={{ margin: '20px auto' }}>
          <ActionButton title="מסלולים וחלוקות" onClick={handleDistributionsClick} />
        </div>

        {hasPermissions([PERMISSION.EDIT_ADDRESSES]) && (
          <div style={{ margin: '20px auto', position: 'relative', overflow: 'hidden' }}>
            <ActionButton title="עריכת כתובות" onClick={handleAllAddressesClick} />
          </div>
        )}

        {hasPermissions([PERMISSION.EDIT_DEFAULT_ROUTES]) && (
          <div style={{ margin: '20px auto', position: 'relative', overflow: 'hidden' }}>
            <ActionButton title="עריכת מסלולים קבועים" onClick={handleDefaultRoutesClick} />
          </div>
        )}

        {hasPermissions([PERMISSION.VIEW_STATISTICS]) && (
          <div style={{ margin: '20px auto', position: 'relative', overflow: 'hidden' }}>
            <ActionButton title="סטטיסטיקות" onClick={handleStatisticsClick} />
          </div>
        )}

        {hasPermissions([PERMISSION.DOWNLOAD_ADDRESSES]) && (
          <div style={{ margin: '20px auto', position: 'relative', overflow: 'hidden' }}>
            <ActionLink href={`${CONFIG.BASE_URL}download-csv.php`} title="ייצוא כתובות לאקסל" />
          </div>
        )}

        {hasPermissions([PERMISSION.VIEW_USERS]) && (
          <div style={{ margin: '20px auto', position: 'relative', overflow: 'hidden' }}>
            <NewBadge />
            <ActionButton title="ניהול משתמשים" onClick={handleManageUsersClick} />
          </div>
        )}

        {
          <div style={{ margin: '20px auto', position: 'relative', overflow: 'hidden' }}>
            <ActionButton title="שיתוף באמצעות QR Code" onClick={handleShowQRClick} />
          </div>
        }

        <div style={{ margin: '20px auto' }}>
          <ActionButton
            title="התנתקות"
            onClick={handleLogoutClick}
            kind="secondary"
            disabled={isLoggingOut}
          />
        </div>
      </div>

      <Modal isVisible={isQRModalVisible} title="שיתוף" onClose={handleCloseQRClick}>
        <img
          src={IMAGES.QR_CODE}
          style={{ width: '100%', maxWidth: 400, margin: '0 auto', display: 'block' }}
        />
      </Modal>
    </>
  );
};
