import { createContext, useContext } from "react";
import { AuthAction } from "./actions-types";
import { AuthState } from "./state";

export const AuthContext = createContext<AuthState | null>(null);
export const AuthDispatchContext = createContext<React.Dispatch<AuthAction> | null>(null);

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('useAuth has to be used within <AuthProvider>');
  }

  return authContext;
};

export const useAuthDispatch = () => {
  const authDispatchContext = useContext(AuthDispatchContext);

  if (!authDispatchContext) {
    throw new Error('authDispatchContext has to be used within <AuthProvider>');
  }

  return authDispatchContext;
};
