import { ActionButton } from '../../../../components/action-button';
import { Modal } from '../../../../components/modal';
import { ButtonsRow, Content } from './style';

type PackageStatusModalProps = {
  isVisible: boolean;
  onLeavePackageSelected: () => void;
  onTakePackageSelected: () => void;
  onClose?: () => void;
};

export const PackageStatusModal = ({
  isVisible,
  onLeavePackageSelected,
  onTakePackageSelected,
  onClose,
}: PackageStatusModalProps) => {
  return (
    <Modal title="חשוב לנו לדעת" isVisible={isVisible} onClose={onClose}>
      <Content>מה עשיתם עם החבילה?</Content>

      <ButtonsRow>
        <ActionButton title="השארנו" onClick={onLeavePackageSelected} type="submit" />
        <ActionButton title="לקחנו" onClick={onTakePackageSelected} />
        {/* <ActionButton title="ביטול" onClick={onClose} kind="secondary" /> */}
      </ButtonsRow>
    </Modal>
  );
};
