import { useReducer, ReactNode, useEffect } from 'react';
import { useMe } from '../../queries/auth';
import { AppLoader } from '../../components/app-loader';
import { LOGIN_STATE, defaultAuthState } from './state';
import { AuthContext, AuthDispatchContext } from './contexts';
import { authReducer } from './reducer';
import { AUTH_ACTION_TYPES } from './actions-types';

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [auth, dispatch] = useReducer(authReducer, defaultAuthState);

  const { mutate: mutateLoadUserData, isLoading } = useMe();

  useEffect(() => {
    if (auth.loginState === LOGIN_STATE.POST_LOGIN || auth.loginState === LOGIN_STATE.INITIALIZE) {
      mutateLoadUserData(
        {},
        {
          onSuccess: (response) => {
            dispatch({
              type: AUTH_ACTION_TYPES.LOADED_USER_INFO,
              payload: {
                permissions: response.permissions,
              },
            });
          },
          onError: () => {
            dispatch({
              type: AUTH_ACTION_TYPES.LOGOUT,
            });
          },
        },
      );
    }
  }, [auth.loginState]);

  const renderContent = () => {
    if (isLoading || auth.loginState === LOGIN_STATE.INITIALIZE) {
      return <AppLoader />;
    }

    return children;
  };

  return (
    <AuthContext.Provider value={auth}>
      <AuthDispatchContext.Provider value={dispatch}>
        {renderContent()}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
};
