import styled from "styled-components";
import { COLORS } from "../../consts/colors";

export const Box = styled.div<{ isChecked: boolean }>`
  display: flex;
  width: 26px;
  height: 26px;
  border: 1px solid ${COLORS.DARK_BLUE};
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: ${({ isChecked }) => (isChecked ? COLORS.DARK_BLUE : COLORS.WHITE)};
  transition: all 0.15s;
`;
