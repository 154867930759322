import { useQuery } from "react-query";
import { CONFIG } from "../config";
import { getAllUsers } from "../api/users";

export const useUsers = () => {
  return useQuery(['users'], () => {
    return getAllUsers();
  }, {
    retry: false,
    refetchInterval: CONFIG.AUTO_REFETCH_INTERVAL,
  });
};
