import { DefaultRoute } from '../../../../@types/default-routes';
import { EditIcon } from '../../../../assets/svgs';
import { Checkbox } from '../../../../components/checkbox';
import { COLORS } from '../../../../consts/colors';
import { TotalAddresses, Content, WatchRouteButton, Wrapper, Title } from './style';

type EditableRouteListItemProps = {
  route: DefaultRoute;
  isSelected: boolean;
  onClick: () => void;
  onEditClick: () => void;
  onToggleSelect: () => void;
};

export const EditableRouteListItem = ({
  route,
  isSelected,
  onClick,
  onEditClick,
  onToggleSelect,
}: EditableRouteListItemProps) => {
  return (
    <Wrapper onClick={onToggleSelect}>
      <Checkbox isChecked={isSelected} />
      <Content>
        <Title>
          {route.name}
        </Title>
        <TotalAddresses>{`${route.totalAddresses} כתובות`}</TotalAddresses>
      </Content>
      <WatchRouteButton
        onClick={(event) => {
          event.stopPropagation();
          onClick();
        }}
      >
        צפייה
      </WatchRouteButton>
      <EditIcon
        style={{ fill: COLORS.DARKER_GRAY }}
        width={23}
        height={23}
        onClick={(event) => {
          event.stopPropagation();
          onEditClick();
        }}
      />
    </Wrapper>
  );
};
