import { Address } from '../../@types/address';
import { AddressListItem } from './address-list-item';
import { EditableAddressListItem } from './editable-address-list-item';

type AddressesListProps = {
  addresses: Address[];
  onAddressClick: (address: Address) => void;
  isEditable?: boolean;
  onRouteOderChanged?: (newAddressesOrder: string[]) => void;
  selectedAddresses?: string[];
};

export const AddressesList = ({
  addresses,
  onAddressClick,
  isEditable,
  onRouteOderChanged,
  selectedAddresses,
}: AddressesListProps) => {
  const swapAddresses = (from: number, to: number) => {
    if (from === to) {
      // nothing to move
      return;
    }

    if (from < 0 || from >= addresses.length) {
      // from index is out of boundaries
      return;
    }

    if (to < 0 || to >= addresses.length) {
      // to index is out of boundaries
      return;
    }

    const addressesIds = addresses.map((address) => address.id);

    const destOldValue = addressesIds[to];
    addressesIds[to] = addressesIds[from];
    addressesIds[from] = destOldValue;

    if (typeof onRouteOderChanged === 'function') {
      onRouteOderChanged(addressesIds);
    }
  };

  const renderListItems = () => {
    if (addresses.length <= 0) {
      return <div>אין כתובות להצגה</div>;
    }

    const currentAddressIdx = addresses.findIndex((address) => {
      // find first address without status
      return typeof address.status === 'undefined' || address.status === null;
    });

    return (
      <>
        {addresses.map((address, index) => {
          return (
            <AddressListItem
              key={address.id}
              isCurrent={currentAddressIdx === index}
              itemCounter={index + 1}
              address={address}
              onClick={() => {
                onAddressClick(address);
              }}
            />
          );
        })}
      </>
    );
  };

  const renderEditableListItems = () => {
    if (addresses.length <= 0) {
      return <div>אין כתובות להצגה</div>;
    }

    return (
      <>
        {addresses.map((address, index) => {
          return (
            <EditableAddressListItem
              onClick={() => {
                onAddressClick(address);
              }}
              key={address.id}
              isSelected={selectedAddresses?.includes(address.id) || false}
              address={address}
              showUpButton={index > 0}
              showDownButton={index < addresses.length - 1}
              onUpClick={() => {
                swapAddresses(index, index - 1);
              }}
              onDownClick={() => {
                swapAddresses(index, index + 1);
              }}
            />
          );
        })}
      </>
    );
  };

  return isEditable ? renderEditableListItems() : renderListItems();
};
