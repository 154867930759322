import styled from 'styled-components';
import { COLORS } from '../../../consts/colors';

export const Wrapper = styled.div<{ done: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0;
  align-items: center;
  cursor: pointer;
  opacity: ${({ done }) => (done ? '0.6' : '1')};
  border-bottom: 1px solid ${COLORS.EXTRA_LIGHT_GRAY};

  &:last-of-type {
    border-bottom: 0;
  }
`;

const StatusIcon = styled.div`
  display: flex;
  width: 35px;
  height: 35px;
  border: 1px solid ${COLORS.DARK_BLUE};
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-left: 20px;
`;

export const CurrentIcon = styled(StatusIcon)`
  background-color: ${COLORS.DARK_BLUE};
  border-color: ${COLORS.DARK_BLUE};
`;

export const OpenedIcon = styled(StatusIcon)`
  background-color: ${COLORS.LIGHT_GRAY};
  border-color: ${COLORS.LIGHT_GRAY};
`;

export const NotOpenedIcon = styled(StatusIcon)`
  background-color: ${COLORS.LIGHT_GRAY};
  border-color: ${COLORS.LIGHT_GRAY};
`;

export const ItemCounter = styled(StatusIcon)`
  // background-color: ${COLORS.DARK_BLUE};
  // color: ${COLORS.WHITE};
  color: ${COLORS.DARK_BLUE};
`;

export const Title = styled.div<{ isCurrent: boolean }>`
  font-size: ${({ isCurrent }) => (isCurrent ? '28px' : '22px')};
`;
