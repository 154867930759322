import styled from 'styled-components';
import { COLORS } from '../../consts/colors';

export const Button = styled.button`
  position: fixed;
  left: 20px;
  bottom: 20px;
  border-radius: 100%;
  border: 0;
  background-color: ${COLORS.DARK_BLUE};
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 50;

  &:hover {
    opacity: 0.9;
  }
`;
