import { memo } from 'react';
import RouteEndedScreen1 from './route-ended';
import RouteEndedScreen2 from './route-ended2';

type ScreenEndProps = {
  onHideEndedScreenClick: () => void;
};

const ScreenEnd = ({ onHideEndedScreenClick }: ScreenEndProps) => {
  const randEndScreen = Math.floor(Math.random() * 2);

  if (randEndScreen % 2 === 0) {
    return <RouteEndedScreen2 onHide={onHideEndedScreenClick} />;
  } else {
    return <RouteEndedScreen1 onHide={onHideEndedScreenClick} />;
  }
};

export default memo(ScreenEnd);
