import { AddIcon } from '../../assets/svgs';
import { COLORS } from '../../consts/colors';
import { Button } from './style';

export const FAB_BOTTOM_SPACING = 80;

type ShareFABProps = {
  onClick: () => void;
};

export const AddFAB = ({ onClick }: ShareFABProps) => {
  return (
    <Button onClick={onClick}>
      <AddIcon height={60} fill={COLORS.WHITE} style={{ padding: '12px 10px 7px 9px' }} />
    </Button>
  );
};
