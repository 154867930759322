import { ExtendedAddress } from '../../../../@types/address';
import { NewBadge } from '../../../badges';
import { Checkbox } from '../../../checkbox';
import { NoRouteLabel, OneTimeBadge, Title, Wrapper } from './style';

type AddressListItemProps = {
  address: ExtendedAddress;
  isSelected: boolean;
  onClick: () => void;
};

export const AddressListItem = ({ address, isSelected, onClick }: AddressListItemProps) => {
  const { street, buildingNumber, status, entrance, apartmentNumber } = address;

  const buildingEntrance = entrance ?? '';
  const apartment = apartmentNumber ? `(דירה ${apartmentNumber})` : '';

  const title = `${street} ${buildingNumber} ${buildingEntrance} ${apartment}`;

  return (
    <Wrapper onClick={onClick} done={!!status}>
      <Checkbox isChecked={isSelected} />
      <Title>
        {title}
        {address._isNewAddress && <NewBadge>חדש</NewBadge>}
        {!!address.oneTime && <OneTimeBadge>{address.oneTime}</OneTimeBadge>}
      </Title>

      {!address.routes && <NoRouteLabel>ללא שיוך</NoRouteLabel>}
    </Wrapper>
  );
};
