export const timeDiff = (date1: Date, date2: Date) => {
  return date1.getTime() - date2.getTime();
};

const formatTime = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  let time = '';

  time += ((hours < 10) ? `0${hours}` : `${hours}`) + ':';
  time += ((minutes < 10) ? `0${minutes}` : `${minutes}`) + ':';
  time += (seconds < 10) ? `0${seconds} ` : `${seconds}`;

  return time;
};

export const formatDateTime = (date: Date) => {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${formatTime(date)}`;
};
