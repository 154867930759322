import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Header } from '../../components/header';
import { useAddresses } from '../../queries/address';
import { getEditAddressRoute } from '../../utils/routes';
import { Address, ExtendedAddress } from '../../@types/address';
import { Input } from '../../components/input';
import { COLORS } from '../../consts/colors';
import { FilterIcon } from '../../assets/svgs';
import { shouldFilterByQuery } from '../../utils/addresses';
import { AddFAB, FAB_BOTTOM_SPACING } from '../../components/add-fab';
import { SCREENS } from '../../consts/screens';
import { useAuthPermissions } from '../../hooks/auth';
import { PERMISSION } from '../../consts/permissions';
import { CheckboxInput } from '../../components/checkbox-input';
import { AddressesList } from './addresses-list';
import { FilterAddressesModal, FilterOptions } from './filter-addresses-modal';
import { CounterRow, FilterRows, ResultsCounter } from './style';

const QUERY_FILTER_KEY = 'query';
const NO_ROUTES_FILTER_KEY = 'noRoutes';
const NO_WAZE_FILTER_KEY = 'noWaze';
const HAS_MULTIPLE_ROUTES_FILTER_KEY = 'multipleRoutes';

export const AllAddressesListScreen = () => {
  const [showInactiveAddress, setShowInactiveAddress] = useState(false);

  const { data, isFetching } = useAddresses(showInactiveAddress);
  const [searchParams, setQuery] = useSearchParams();
  const [isFiltersModalVisible, setFiltersModalVisible] = useState(false);

  const { hasPermissions } = useAuthPermissions();

  const filters = {
    [QUERY_FILTER_KEY]: searchParams.get(QUERY_FILTER_KEY) || '',
    [NO_ROUTES_FILTER_KEY]: searchParams.get(NO_ROUTES_FILTER_KEY) === '1',
    [NO_WAZE_FILTER_KEY]: searchParams.get(NO_WAZE_FILTER_KEY) === '1',
    [HAS_MULTIPLE_ROUTES_FILTER_KEY]: searchParams.get(HAS_MULTIPLE_ROUTES_FILTER_KEY) === '1',
  };
  const allAddresses = data?.addresses || [];

  const navigate = useNavigate();

  const handleAddAddressClick = () => {
    navigate(SCREENS.CREATE_ADDRESS);
  };

  const handleAddressClick = (address: Address) => {
    navigate(getEditAddressRoute(address.id));
  };

  const handleFilterQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    setQuery(
      {
        [QUERY_FILTER_KEY]: value,

        [NO_ROUTES_FILTER_KEY]: filters[NO_ROUTES_FILTER_KEY] ? '1' : '0',
        [NO_WAZE_FILTER_KEY]: filters[NO_WAZE_FILTER_KEY] ? '1' : '0',
        [HAS_MULTIPLE_ROUTES_FILTER_KEY]: filters[HAS_MULTIPLE_ROUTES_FILTER_KEY] ? '1' : '0',
      },
      {
        replace: true,
      },
    );
  };

  const handleFilterOptionsChange = (options: FilterOptions) => {
    setQuery(
      {
        ...filters,
        [NO_ROUTES_FILTER_KEY]: options.noRoutes ? '1' : '0',
        [NO_WAZE_FILTER_KEY]: options.noWaze ? '1' : '0',
        [HAS_MULTIPLE_ROUTES_FILTER_KEY]: options.multipleRoutes ? '1' : '0',
      },
      {
        replace: true,
      },
    );

    setFiltersModalVisible(false);
  };

  const handleOpenFilterModalClick = () => {
    setFiltersModalVisible(true);
  };

  const handleFilterModalCancel = () => {
    setFiltersModalVisible(false);
  };

  const filterByOptions = (address: ExtendedAddress) => {
    if (filters[NO_ROUTES_FILTER_KEY]) {
      // filter out if there is a route
      if (address.routes) {
        return false;
      }
    }

    if (filters[NO_WAZE_FILTER_KEY]) {
      // filter out if there are location details
      if (address.location && address.location.latitude && address.location.longitude) {
        return false;
      }
    }

    if (filters[HAS_MULTIPLE_ROUTES_FILTER_KEY]) {
      // filter out if there is 1 route
      if (!address.routes || address.routes.split(',').length <= 1) {
        return false;
      }
    }

    return true;
  };

  const addressesList =
    allAddresses.filter(filterByOptions).filter((address) => {
      return shouldFilterByQuery(address, filters[QUERY_FILTER_KEY]);
    }) || [];

  return (
    <>
      <Header screenTitle="רשימת כתובות" isLoading={isFetching} hasBack />
      <div
        style={{
          width: '95%',
          margin: '10px auto 0 auto',
          paddingBottom: FAB_BOTTOM_SPACING,
        }}
      >
        {hasPermissions([PERMISSION.CREATE_ADDRESSES]) && (
          <AddFAB onClick={handleAddAddressClick} />
        )}

        <FilterRows>
          <Input
            value={filters[QUERY_FILTER_KEY]}
            placeholder="סינון"
            onChange={handleFilterQueryChange}
          />

          <FilterIcon
            onClick={handleOpenFilterModalClick}
            style={{
              height: 50,
              backgroundColor: COLORS.DARK_BLUE,
              padding: '0px 3px',
              cursor: 'pointer',
            }}
            fill={COLORS.WHITE}
          />
        </FilterRows>

        <CounterRow>
          <ResultsCounter>
            {`מציג ${addressesList.length} מתוך ${allAddresses.length}`}
          </ResultsCounter>

          <CheckboxInput
            isChecked={showInactiveAddress}
            label="כולל כתובות מחוקות"
            onToggleCheck={() => {
              setShowInactiveAddress(!showInactiveAddress);
            }}
          />
        </CounterRow>

        <div
          style={{
            maxHeight: '65vh',
            border: `1px solid ${COLORS.EXTRA_LIGHT_GRAY}`,
            overflow: 'auto',
            padding: '10px 20px',
            marginTop: 20,
          }}
        >
          <AddressesList addresses={addressesList} onAddressClick={handleAddressClick} />
        </div>
      </div>

      {isFiltersModalVisible && (
        <FilterAddressesModal
          activeOptions={filters}
          onCancel={handleFilterModalCancel}
          onSaveClick={handleFilterOptionsChange}
        />
      )}
    </>
  );
};
