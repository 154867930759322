import { Text } from './style';

type NotActiveWarningProps = {
  text: string;
};

const NotActiveWarning = ({ text }: NotActiveWarningProps) => {
  return <Text>{text}</Text>;
};

export const RouteNotActiveWarning = () => {
  return <NotActiveWarning text="המסלול לא פעיל" />;
};

export const DistributionNotActiveWarning = () => {
  return <NotActiveWarning text="החלוקה לא פעילה" />;
};

export const AddressNotActiveWarning = () => {
  return <NotActiveWarning text="הכתובת לא פעילה" />;
};
