import { LoaderIndicator, Logo, Wrapper } from './style';

export const AppLoader = () => {
  return (
    <Wrapper>
      <LoaderIndicator />
      <Logo />
    </Wrapper>
  );
};
