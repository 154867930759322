import { useState } from 'react';
import { ActionButton } from '../../../components/action-button';
import { Modal } from '../../../components/modal';
import { useCreateDistribution, useDistributions } from '../../../queries/distributions';
import { ButtonsRow, CustomInput, ErrorMessage } from './style';

const NO_ROUTES_OPTION_VALUE = '';
const DEFAULT_ROUTES_OPTION_VALUE = 'DEFAULT_ROUTES';

type CreateDistributionModalProps = {
  onDistributionCreated: () => void;
  onCancel: () => void;
};

export const CreateDistributionModal = ({
  onDistributionCreated,
  onCancel,
}: CreateDistributionModalProps) => {
  const [distributionName, setDistributionName] = useState<string>('');
  const [sourceDistributionId, setSourceDistributionId] = useState<string>(
    DEFAULT_ROUTES_OPTION_VALUE,
  );
  const [errorMessage, setErrorMessage] = useState('');

  // todo: isLoading
  const { data: distributions = [] } = useDistributions();
  const { mutate: mutateCreateDistribution } = useCreateDistribution();

  const handleDistributionNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDistributionName(event.currentTarget.value);
  };

  const handleRouteSourceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSourceDistributionId(event.currentTarget.value);
  };

  const handleSaveClick = () => {
    setErrorMessage('');

    if (!distributionName) {
      setErrorMessage('שם החלוקה לא יכול להיות ריק');

      return;
    }

    mutateCreateDistribution(
      {
        payload: {
          name: distributionName,
          duplicateFrom: sourceDistributionId,
        },
      },
      {
        onSuccess: () => {
          onDistributionCreated();
        },
      },
    );
  };

  return (
    <Modal title="יצירת חלוקה חדשה" isVisible onClose={onCancel}>
      <form
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleSaveClick();
        }}
      >
        <CustomInput
          label="שם החלוקה"
          value={distributionName}
          onChange={handleDistributionNameChange}
        />

        <select onChange={handleRouteSourceChange} value={sourceDistributionId}>
          <option value={NO_ROUTES_OPTION_VALUE}>ללא מסלולים</option>
          <option value={DEFAULT_ROUTES_OPTION_VALUE}>כל המסלולים</option>

          <optgroup label="חלוקות קודמות">
            {distributions.map((distribution) => {
              return <option value={distribution.id}>{distribution.name}</option>;
            })}
          </optgroup>
        </select>

        {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

        <ButtonsRow>
          <ActionButton title="שמירה" type="submit" />
          <ActionButton title="ביטול" onClick={onCancel} kind="secondary" />
        </ButtonsRow>
      </form>
    </Modal>
  );
};
