import { Address } from '../../../../@types/address';
import { NewBadge } from '../../../../components/badges';
import { OneTimeBadge, Title, Wrapper } from './style';

type AddressListItemProps = {
  address: Address;
  onClick: () => void;
};

export const AddressListItem = ({ address, onClick }: AddressListItemProps) => {
  const {
    street,
    buildingNumber,
    entrance,
    apartmentNumber,
    oneTime,
    active,
    _isNewAddress,
  } = address;

  const buildingEntrance = entrance ?? '';
  const apartment = apartmentNumber ? `(דירה ${apartmentNumber})` : '';

  const title = `${street} ${buildingNumber} ${buildingEntrance} ${apartment}`;

  const renderOneTimeBadge = () => {
    if (!oneTime) {
      return null;
    }

    return <OneTimeBadge>חד פעמי: {oneTime}</OneTimeBadge>;
  };

  return (
    <Wrapper onClick={onClick}>
      <Title isActive={active}>{title}</Title>

      {_isNewAddress && <NewBadge>חדש</NewBadge>}
      {renderOneTimeBadge()}
    </Wrapper>
  );
};
