export const SCREENS = {
  HOME: '/',
  DEFAULT_ROUTES: '/default-routes',
  DEFAULT_ROUTE: '/default-route/:routeId',
  DISTRIBUTIONS: '/distributions',
  EDIT_DISTRIBUTIONS: '/distributions/edit',
  ADDRESS_LOCATOR: '/route/:routeId/address/:addressId',
  ALL_ADDRESSES: '/addresses',
  EDIT_ADDRESS: '/address/:addressId/edit',
  CREATE_ADDRESS: '/addresses/add',
  STATISTICS: '/statistics',
  USERS: '/users',
};
