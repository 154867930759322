import styled from 'styled-components';

export const FilterRows = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const CounterRow = styled.div`
  display: flex;
  flexDirection: row;
  margin-top: 3px;
`;

export const ResultsCounter = styled.div`
  flex: 1;
`;
