import styled from 'styled-components';
import { COLORS } from '../../../../consts/colors';

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px auto;
  gap: 10px;
`;

export const Title = styled.div`
  flex: 1;
  font-weight: bold;
  line-height: 22px;
  padding-top: 3px; /* align font top */
  color: ${COLORS.DARKER_GRAY};
`;

export const Value = styled.div<{
  isLTR?: boolean;
}>`
  flex: 2;
  line-height: 22px;
  direction: ${({ isLTR }) => (isLTR ? 'ltr' : 'rtl')};
  text-align: right;
  color: ${COLORS.DARKER_GRAY};
`;
