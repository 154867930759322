import styled from 'styled-components';
import { COLORS } from '../../consts/colors';

export type ActionButtonType = 'primary' | 'secondary';

export const Link = styled.a<{
  buttonType: ActionButtonType;
  isDisabled: boolean;
}>`
  display: block;
  background-color: ${(props) =>
    props.buttonType === 'primary' ? COLORS.DARK_BLUE : COLORS.LIGHT_BLUE};
  color: white;
  border: 0;
  box-shadow: none;
  padding: 20px;
  width: 100%;
  cursor: pointer;
  font-family: 'NextExitRound', Arial;
  font-size: 26px;
  line-height: 22px;
  text-align: center;
  text-decoration: none;

  ${(props) => {
    if (props.isDisabled) {
      return `
      pointer-events: none;
      opacity: 0.5;
      cursor: default;
      `;
    }
  }}

  &:hover {
    opacity: 0.9;
  }
`;
