import { ShareIcon } from '../../assets/svgs';
import { COLORS } from '../../consts/colors';
import { Button } from './style';

export const FAB_BOTTOM_SPACING = 80;

type ShareFABProps = {
  message?: string;
};

export const ShareFAB = ({ message }: ShareFABProps) => {
  let messageText = window.location.href;

  if (message && message.length > 0) {
    // add message only if it's not empty
    messageText = `${message} ${window.location.href}`;
  }

  const handleShareClick = async () => {
    window.open(`https://wa.me/?text=${encodeURI(messageText)}`, '_blank');
  };

  return (
    <Button onClick={handleShareClick}>
      <ShareIcon height={60} fill={COLORS.WHITE} style={{ padding: '15px 15px 10px 10px' }} />
    </Button>
  );
};
