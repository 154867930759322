import { Route } from '../../../../@types/routes';
import { EditIcon } from '../../../../assets/svgs';
import { PackagesBadge } from '../../../../components/badges';
import { Checkbox } from '../../../../components/checkbox';
import { NotActiveBadge } from '../../../../components/not-active-badge/style';
import { COLORS } from '../../../../consts/colors';
import { People, Content, WatchRouteButton, Wrapper, Title } from './style';

type EditableRouteListItemProps = {
  route: Route;
  isSelected: boolean;
  onClick: () => void;
  onEditClick: () => void;
  onToggleSelect: () => void;
};

export const EditableRouteListItem = ({
  route,
  isSelected,
  onClick,
  onEditClick,
  onToggleSelect,
}: EditableRouteListItemProps) => {
  const { driver, passengers } = route;

  let people = '';

  if (driver) {
    people = driver;
  }

  if (passengers) {
    if (people) {
      people += ' + ';
    }

    people += passengers;
  }

  return (
    <Wrapper onClick={onToggleSelect}>
      <Checkbox isChecked={isSelected} />
      <Content>
        <Title>
          {route.name}
          <PackagesBadge>{route.totalPackages} אריזות</PackagesBadge>
          {!route?.isActive && <NotActiveBadge>לא פעיל</NotActiveBadge>}
        </Title>
        <People>{people}</People>
      </Content>
      <WatchRouteButton
        onClick={(event) => {
          event.stopPropagation();
          onClick();
        }}
      >
        צפייה
      </WatchRouteButton>
      <EditIcon
        style={{ fill: COLORS.DARKER_GRAY }}
        width={23}
        height={23}
        onClick={(event) => {
          event.stopPropagation();
          onEditClick();
        }}
      />
    </Wrapper>
  );
};
