import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { LoginResponse, MeResponse, login, logout, me } from "../api/auth";

export const useLogin = () => {
  return useMutation<
    LoginResponse,
    AxiosError,
    {
      username: string,
      password: string,
    }>(['login'], async ({ username, password }) => {
      return login(username, password);
    });
};

export const useMe = () => {
  return useMutation<
    MeResponse,
    AxiosError, {}>(['me'], async () => {
      return me();
    });
};

export const useLogout = () => {
  return useMutation<
    void,
    AxiosError, {}>(['logout'], async () => {
      return logout();
    });
};
