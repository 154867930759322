import { useQuery } from 'react-query';
import { Modal } from '../modal';
import { getAddressHistory } from '../../api/address';
import { CONFIG } from '../../config';
import { HistoryRecordItem } from './history-record-item';
import { HistoryLine, HistoryList } from './style';

type AddressHistoryModalProps = {
  addressId: string;
  onClose?: () => void;
};

export const AddressHistoryModal = ({ addressId, onClose }: AddressHistoryModalProps) => {
  const { data: history, isLoading } = useQuery(
    ['addresses', addressId],
    () => {
      return getAddressHistory(addressId);
    },
    {
      retry: false,
      refetchInterval: CONFIG.AUTO_REFETCH_INTERVAL,
    },
  );

  const renderHistoryRecords = () => {
    if (isLoading) {
      return "טוען...";
    }

    if (!history || history.records.length <= 0) {
      return <div>אין מידע להצגה</div>;
    }

    return (
      <HistoryList>
        <HistoryLine />
        {history.records.map((record) => {
          return <HistoryRecordItem key={record.distributionId} record={record} />;
        })}
      </HistoryList>
    );
  };

  return (
    <Modal title="היסטוריית חלוקה" isVisible onClose={onClose}>
      {renderHistoryRecords()}
    </Modal>
  );
};
