import { Address } from '../../../@types/address';
import { DoneIcon, DownArrowIcon, UpArrowIcon } from '../../../assets/svgs';
import { COLORS } from '../../../consts/colors';
import { NewBadge, PackagesBadge } from '../../badges';
import { Checkbox, Title, Wrapper } from './style';

type EditableAddressListItemProps = {
  address: Address;
  isSelected: boolean;
  showUpButton: boolean;
  showDownButton: boolean;
  onClick: () => void;
  onUpClick: () => void;
  onDownClick: () => void;
};

export const EditableAddressListItem = ({
  address,
  isSelected,
  showUpButton,
  showDownButton,
  onClick,
  onUpClick,
  onDownClick,
}: EditableAddressListItemProps) => {
  const { street, buildingNumber, totalPackages, entrance, apartmentNumber } = address;

  const buildingEntrance = entrance ?? '';
  const apartment = apartmentNumber ? `(דירה ${apartmentNumber})` : '';

  const title = `${street} ${buildingNumber} ${buildingEntrance} ${apartment}`;

  const renderPackagesCounter = () => {
    if (totalPackages <= 1) {
      return null;
    }

    return <PackagesBadge>{totalPackages} אריזות</PackagesBadge>;
  };

  return (
    <Wrapper onClick={onClick}>
      <Checkbox isChecked={isSelected}>
        <DoneIcon style={{ fill: COLORS.WHITE }} width={23} height={23} />
      </Checkbox>
      <Title>
        {title}
        {address._isNewAddress && <NewBadge>חדש</NewBadge>}
        {renderPackagesCounter()}
      </Title>

      {showDownButton && (
        <DownArrowIcon
          style={{ fill: COLORS.DARK_BLUE }}
          width={34}
          height={34}
          onClick={(event) => {
            event.stopPropagation();
            onDownClick();
          }}
        />
      )}

      {showUpButton && (
        <UpArrowIcon
          style={{ fill: COLORS.DARK_BLUE }}
          width={34}
          height={34}
          onClick={(event) => {
            event.stopPropagation();
            onUpClick();
          }}
        />
      )}
    </Wrapper>
  );
};
