import styled, { keyframes } from 'styled-components';
import { IMAGES } from '../../assets/images';
import { BackIcon, MenuIcon } from '../../assets/svgs';
import { COLORS } from '../../consts/colors';

// todo: colors
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 70px;
  z-index: 50;
  position: sticky;
  top: 0;
  background-color: ${COLORS.DARK_BLUE};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02),
    0 2px 4px 0 rgba(0, 0, 0, 0.02);
`;

export const MenuButton = styled(MenuIcon)`
  width: 40px;
  height: 40px;
  cursor: pointer;
  fill: ${COLORS.WHITE};
`;

export const BackButton = styled(BackIcon)`
  width: 40px;
  height: 40px;
  cursor: pointer;
  fill: ${COLORS.WHITE};

  &:hover {
    opacity: 0.9;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 10px;
  min-width: 0; /* https://stackoverflow.com/a/39857087/7550127 */
`;

export const Logo = styled.img.attrs({
  src: IMAGES.LOGO,
})`
  height: 55px;
  justify-self: center;
`;

export const ScreenTitle = styled.h1`
  color: ${COLORS.WHITE};
  font-size: 32px;
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const ScreenSubTitle = styled.h2`
  color: ${COLORS.WHITE};
  font-size: 26px;
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const ButtonWrapper = styled.div<{
  disabled?: boolean;
}>`
  height: 40px;
  width: 40px;
  margin: 10px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  fill: ${COLORS.WHITE};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  position: relative;

  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.5 : 0.9)};
  }
`;

export const Backdrop = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
`;

export const OverflowMenuWrapper = styled.div`
  display: block;
  width: 200px;
  background-color: ${COLORS.WHITE};
  border-radius: 2px;
  box-shadow: 1px 1px 5px ${COLORS.DARK_GRAY};
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 999;
`;

export const OverflowMenuItem = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  width: 100%;
  padding: 10px 10px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  &:hover {
    background-color: ${({ disabled }) => (disabled ? 'transparent' : COLORS.EXTRA_LIGHT_GRAY)};
  }
`;

export const OverflowMenuItemIcon = styled.div`
  display: flex;
  width: 25px;
  justify-content: center;
  margin-left: 10px;
  color: ${COLORS.DARKER_GRAY};
  fill: ${COLORS.DARKER_GRAY};
`;

export const OverflowMenuItemTitle = styled.div`
  flex: 1;
`;

const indeterminateAnimation = keyframes`
  0% {
    left: -100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 10%;
  }
`;

export const Loader = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 3px;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    background-color: ${COLORS.WHITE};
    animation: ${indeterminateAnimation} 1.5s infinite ease-out;
  }
`;
