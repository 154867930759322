import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Header, HeaderButton } from '../../components/header';
import { Route } from '../../@types/routes';
import { BlockIcon, MergeIcon, PlayIcon, StopIcon } from '../../assets/svgs';
import { SaveRoutePayload, useResetAllRoutesTeams, useSetRouteInfo } from '../../queries/route';
import { useMergeRoutes, useSetRoutesActiveState } from '../../queries/routes';
import { ConfirmationModal } from '../../components/confirmation-modal';
import { PromptModal } from '../../components/prompt-modal';
import { AlertModal } from '../../components/alert-modal';
import { useDistribution } from '../../queries/distributions';
import { DistributionNotActiveWarning } from '../../components/not-active-warning';
import { AddFAB, FAB_BOTTOM_SPACING } from '../../components/add-fab';
import { RoutesList } from './routes-list';
import { EditRouteModal } from './edit-route-modal';
import { AddRouteModal } from './add-route-modal';

export const RoutesEditorScreen = () => {
  const navigate = useNavigate();
  const { distributionId = '' } = useParams();
  const [selectedRoutes, setSelectedRoutes] = useState<string[]>([]);
  const [routeToEdit, setRouteToEdit] = useState<Route | null>(null);

  const [isAddRouteModalVisible, setAddRouteModalVisibility] = useState(false);

  const [isConfirmResetTeamsModalVisible, setConfirmResetTeamsModalVisibility] = useState(false);
  const [isPromptMergeRoutesModalVisible, setPromptMergeRoutesModalVisibility] = useState(false);
  const [isAlertMergeRoutesModalVisible, setAlertMergeRoutesModalVisibility] = useState(false);

  const {
    isFetching,
    data: distribution,
    refetch: refetchRoutesList,
    isLoading,
  } = useDistribution(distributionId);
  const { mutate: mutateRouteInfo, isLoading: isUpdating } = useSetRouteInfo();
  const { mutate: mutateMergeRoutes, isLoading: isMerging } = useMergeRoutes();
  const { mutate: mutateRoutesActiveState, isLoading: isUpdatingActiveState } =
    useSetRoutesActiveState();
  const { mutate: mutateResetAllTeams, isLoading: isResetTeams } = useResetAllRoutesTeams();

  const toggleRouteSelect = (route: Route) => {
    const newSelectedRoutes = [...selectedRoutes];

    const itemIndex = newSelectedRoutes.findIndex((selectedItem) => {
      return selectedItem === route.id;
    });

    if (itemIndex >= 0) {
      newSelectedRoutes.splice(itemIndex, 1);
    } else {
      newSelectedRoutes.push(route.id);
    }

    setSelectedRoutes(newSelectedRoutes);
  };

  const handleMergeClick = () => {
    if (selectedRoutes.length < 2) {
      setAlertMergeRoutesModalVisibility(true);

      return;
    }

    setPromptMergeRoutesModalVisibility(true);
  };

  const handleConfirmMergeAlertClick = () => {
    setAlertMergeRoutesModalVisibility(false);
  };

  const handleSaveMergeClick = (newRouteName: string) => {
    setPromptMergeRoutesModalVisibility(false);

    mutateMergeRoutes(
      {
        distributionId,
        newRouteName,
        routesIds: selectedRoutes,
      },
      {
        onSuccess: () => {
          setSelectedRoutes([]);
          refetchRoutesList();
        },
      },
    );
  };

  const changeRoutesActiveState = (newState: boolean) => {
    mutateRoutesActiveState(
      {
        routesIds: selectedRoutes,
        newState: newState,
      },
      {
        onSuccess: () => {
          setSelectedRoutes([]);
          refetchRoutesList();
        },
      },
    );
  };

  const handleSetRoutesActiveClick = () => {
    changeRoutesActiveState(true);
  };

  const handleSetRoutesNotActiveClick = () => {
    changeRoutesActiveState(false);
  };

  const handleCancelMergeClick = () => {
    setPromptMergeRoutesModalVisibility(false);
  };

  const handleEditRouteClick = (route: Route) => {
    setRouteToEdit(route);
  };

  const handleSaveRouteClick = (routePayload: SaveRoutePayload) => {
    setRouteToEdit(null);

    mutateRouteInfo(routePayload, {
      onSuccess: () => {
        refetchRoutesList();
      },
    });
  };

  const handleResetTeamsClick = () => {
    setConfirmResetTeamsModalVisibility(true);
  };

  const handleConfirmResetTeamsClick = () => {
    setConfirmResetTeamsModalVisibility(false);

    mutateResetAllTeams(
      {
        distributionId,
      },
      {
        onSuccess: () => {
          refetchRoutesList();
        },
      },
    );
  };

  const handleCancelResetTeamsClick = () => {
    setConfirmResetTeamsModalVisibility(false);
  };

  const handleCreateRouteClick = () => {
    setAddRouteModalVisibility(true);
  };

  const renderScreenTitle = () => {
    if (selectedRoutes.length > 0) {
      return `נבחרו: ${selectedRoutes.length}`;
    }

    return 'עריכת מסלולים';
  };

  const renderActionButtons = () => {
    const buttons: HeaderButton[] = [];

    if (selectedRoutes.length > 0) {
      const isAllActive = distribution?.routes
        .filter((route) => selectedRoutes.includes(route.id))
        .every((route) => route.isActive);

      if (isAllActive) {
        buttons.push({
          title: 'השבתת מסלול',
          icon: <StopIcon />,
          onclick: handleSetRoutesNotActiveClick,
        });
      } else {
        buttons.push({
          title: 'הפעלת מסלול',
          icon: <PlayIcon />,
          onclick: handleSetRoutesActiveClick,
        });
      }

      buttons.push({ title: 'איחוד', icon: <MergeIcon />, onclick: handleMergeClick });
    }

    buttons.push({ title: 'איפוס צוותים', icon: <BlockIcon />, onclick: handleResetTeamsClick });

    return buttons;
  };

  return (
    <>
      <Header
        screenTitle={renderScreenTitle()}
        hasBack
        isLoading={isFetching || isUpdating || isMerging || isResetTeams || isUpdatingActiveState}
        buttons={renderActionButtons()}
      />
      <div style={{ width: '95%', margin: '10px auto 0 auto', paddingBottom: FAB_BOTTOM_SPACING }}>
        {!isLoading && !distribution?.isActive && <DistributionNotActiveWarning />}

        <AddFAB onClick={handleCreateRouteClick} />

        <RoutesList
          routes={distribution?.routes || []}
          onRouteClick={(route) => {
            navigate(`/distribution/${distributionId}/route/${route.id}`);
          }}
          onToggleRouteSelect={toggleRouteSelect}
          selectedRoutes={selectedRoutes}
          onEditRouteClick={handleEditRouteClick}
        />
      </div>

      {routeToEdit && (
        <EditRouteModal
          route={routeToEdit}
          onSaveClick={handleSaveRouteClick}
          onCancel={() => {
            setRouteToEdit(null);
          }}
        />
      )}

      {isAddRouteModalVisible && (
        <AddRouteModal
          distributionId={distributionId}
          onRouteAdded={() => {
            refetchRoutesList();
            setAddRouteModalVisibility(false);
          }}
          onCancel={() => {
            setAddRouteModalVisibility(false);
          }}
        />
      )}

      <ConfirmationModal
        title="איפוס צוותים"
        isVisible={isConfirmResetTeamsModalVisible}
        onConfirm={handleConfirmResetTeamsClick}
        onCancel={handleCancelResetTeamsClick}
      >
        לאפס את כל הנהגים והנוסעים שמשוייכים למסלולים?
      </ConfirmationModal>

      {isPromptMergeRoutesModalVisible && (
        <PromptModal
          title="איחוד מסלולים"
          inputLabel="שם המסלול החדש"
          onSaveClick={handleSaveMergeClick}
          onCancel={handleCancelMergeClick}
        />
      )}

      <AlertModal
        isVisible={isAlertMergeRoutesModalVisible}
        onConfirm={handleConfirmMergeAlertClick}
      >
        יש לבחור לפחות 2 מסלולים לאיחוד
      </AlertModal>
    </>
  );
};
