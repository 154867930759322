import styled from 'styled-components';
import { COLORS } from '../../consts/colors';
import { Input } from '../input';

export const CustomInput = styled(Input)`
  margin-bottom: 10px;
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 30px;
`;

export const ErrorMessage = styled.div`
  color: ${COLORS.LIGHT_RED};
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  margin-bottom: -10px;
`;
