import { CustomTextarea, ErrorMessage, LabelText, LabelWrapper } from './style';

type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  hasError?: boolean;
  errorMessage?: string;
};

export const Textarea = ({
  label,
  hasError = false,
  errorMessage,
  ...textareaProps
}: TextareaProps) => {
  return (
    <LabelWrapper>
      <LabelText>{label}</LabelText>
      <CustomTextarea {...textareaProps} hasError={hasError} />
      {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </LabelWrapper>
  );
};
