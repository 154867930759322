export enum PERMISSION {
  // me
  VIEW_ME = 'me:view',
  EDIT_ME = 'me:edit',

  // default-routes
  VIEW_DEFAULT_ROUTES = 'default-routes:*:view',
  EDIT_DEFAULT_ROUTES = 'default-routes:*:edit',
  CREATE_DEFAULT_ROUTES = 'default-routes:create',

  // distributions
  VIEW_DISTRIBUTIONS = 'distributions:*:view',
  EDIT_DISTRIBUTIONS = 'distributions:*:edit',
  CREATE_DISTRIBUTIONS = 'distributions:create',

  // routes
  VIEW_ROUTES = 'routes:*:view',
  EDIT_ROUTES = 'routes:*:edit',

  // addresses
  VIEW_ADDRESSES = 'address:*:view',
  EDIT_ADDRESSES = 'address:*:edit',
  CREATE_ADDRESSES = 'address:create',
  DELETE_ADDRESSES = 'address:delete',
  DOWNLOAD_ADDRESSES = 'address:*:download',
  VIEW_ADDRESSES_HISTORY = 'address:*:history',

  // statistics
  VIEW_STATISTICS = 'statistics:view',

  // users list
  VIEW_USERS = 'users:*:view',
};
