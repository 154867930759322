import { useMutation, useQuery } from "react-query";
import { AxiosError } from "axios";
import { CONFIG } from "../config";
import {
  CreateDistributionPayload,
  addDefaultRoutesToDistribution,
  createDistribution, getAllDistributions, getDistribution, setDistributionsActiveState,
} from "../api/distributions";

export const useDistributions = () => {
  return useQuery(['distributions'], () => {
    return getAllDistributions();
  }, {
    retry: false,
    refetchInterval: CONFIG.AUTO_REFETCH_INTERVAL,
  });
};

export const useDistribution = (distributionId: string) => {
  return useQuery(['distribution', distributionId], () => {
    return getDistribution(distributionId);
  }, {
    retry: false,
    refetchInterval: CONFIG.AUTO_REFETCH_INTERVAL,
  });
};

export const useCreateDistribution = () => {
  return useMutation<
    void,
    AxiosError,
    {
      payload: CreateDistributionPayload,
    }>(['create-distribution'], async ({ payload }) => {
      return createDistribution(payload);
    });
};

export const useSetDistributionsActiveState = () => {
  return useMutation<
    void,
    AxiosError,
    {
      distributionsIds: string[],
      newState: boolean,
    }>(['set-active-distributions'], async ({ distributionsIds, newState }) => {
      return setDistributionsActiveState(distributionsIds, newState);
    });
};

export const useAddDefaultRoutesToDistribution = () => {
  return useMutation<
    void,
    AxiosError,
    {
      distributionId: string,
      defaultRoutesIds: string[],
    }>(['add-default-routes-to-distribution'], async ({ distributionId, defaultRoutesIds }) => {
      return addDefaultRoutesToDistribution(distributionId, defaultRoutesIds);
    });
};
