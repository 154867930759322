import { Address } from "../@types/address";
import { inStrings } from "./strings";

export const shouldFilterByQuery = (address: Address, query: string) => {
  const { city, street, buildingNumber, entrance } = address;

  const buildingEntrance = entrance ?? '';

  const fullAddressString = `${city} ${street} ${buildingNumber} ${buildingEntrance}`;
  const fullAddressNoCharsString = fullAddressString.replace(/["'\.]/, '');

  return inStrings(query, [
    address.city,
    address.street,
    address.buildingNumber.toString(),
    address.entrance || '',
    address.floor?.toString() || '',
    address.apartmentNumber?.toString() || '',
    address.familyName || '',
    address.familyPhoneNumber || '',
    address.code || '',
    address.contactName || '',
    address.contactPhoneNumber || '',
    fullAddressString,
    fullAddressNoCharsString,
  ]);

};
