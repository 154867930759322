import { useMutation, useQuery } from "react-query";
import { AxiosError } from "axios";
import { CONFIG } from "../config";
import {
  createDefaultRoute,
  deleteAddressesFromDefaultRoute,
  getAllDefaultRoutes,
  getDefaultRoute,
  linkAddressesToDefaultRoute,
  mergeDefaultRoutes,
  moveAddressesToNewDefaultRoute,
  setDefaultRouteInfo,
  setDefaultRouteOrder,
} from "../api/default-routes";

export type SaveDefaultRoutePayload = {
  routeId: string;
  name: string;
};

export const useDefaultRoutes = () => {
  return useQuery(['default-routes'], () => {
    return getAllDefaultRoutes();
  }, {
    retry: false,
    refetchInterval: CONFIG.AUTO_REFETCH_INTERVAL,
  });
};

export const useDefaultRoute = (routeId: string) => {
  return useQuery(['default-route', routeId], () => {
    return getDefaultRoute(routeId);
  }, {
    retry: false,
    refetchInterval: CONFIG.AUTO_REFETCH_INTERVAL,
  });
};

export const useCreateDefaultRoute = () => {
  return useMutation<
    void,
    AxiosError,
    {
      routeName: string,
    }>(['create-default-route'], async ({ routeName }) => {
      return createDefaultRoute(routeName);
    });
};

export const useSetDefaultRouteInfo = () => {
  return useMutation<
    void,
    AxiosError,
    SaveDefaultRoutePayload>(
      ['default-route-info'], async ({ routeId, name }) => {
        return setDefaultRouteInfo(routeId, name);
      });
};

export const useMergeDefaultRoutes = () => {
  return useMutation<
    void,
    AxiosError,
    {
      newRouteName: string,
      routesIds: string[],
    }>(['merge-default-routes'], async ({ newRouteName, routesIds }) => {
      return mergeDefaultRoutes(newRouteName, routesIds);
    });
};

export const useMoveAddressesToNewDefaultRoute = () => {
  return useMutation<
    void,
    AxiosError,
    {
      oldRouteId: string,
      newRouteName: string,
      AddressesList: string[],
    }>(['addresses-new-default-route'], async ({
      oldRouteId, newRouteName, AddressesList,
    }) => {
      return moveAddressesToNewDefaultRoute(oldRouteId, newRouteName, AddressesList);
    });
};

export const useSetDefaultRouteOrder = (
  routeId: string,
) => {
  return useMutation<
    void,
    AxiosError,
    {
      addresses: string[]
    }>(['default-route-order', routeId], async ({ addresses }) => {
      return setDefaultRouteOrder(routeId, addresses);
    });
};

export const useDeleteAddressesFromDefaultRoute = () => {
  return useMutation<
    void,
    AxiosError,
    {
      routeId: string,
      addressesList: string[],
    }>(['addresses-new-default-route'], async ({ routeId, addressesList }) => {
      return deleteAddressesFromDefaultRoute(routeId, addressesList);
    });
};

export const useLinkAddressesToDefaultRoute = (
  routeId: string,
) => {
  return useMutation<
    void,
    AxiosError,
    {
      addresses: string[]
    }>(['link-addresses-to-default-route', routeId], async ({ addresses }) => {
      return linkAddressesToDefaultRoute(routeId, addresses);
    });
};
