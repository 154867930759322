import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { Header, HeaderButton } from '../../components/header';
import { getAddress } from '../../api/address';
import { Address } from '../../@types/address';
import { useSetAddressActiveState, useUpdateAddress } from '../../queries/address';
import { AddressForm } from '../../components/address-form';
import { useAuthPermissions } from '../../hooks/auth';
import { PERMISSION } from '../../consts/permissions';
import { DeleteIcon, RestoreIcon, TimelineIcon } from '../../assets/svgs';
import { AddressHistoryModal } from '../../components/address-history-modal';
import { AddressNotActiveWarning } from '../../components/not-active-warning';
import { AlertModal } from '../../components/alert-modal';
import { ConfirmationModal } from '../../components/confirmation-modal';

// todo: onbeforeunload

export const AddressEditorScreen = () => {
  const { addressId = '' } = useParams();
  const [searchParams, setQuery] = useSearchParams();
  const navigate = useNavigate();
  const { hasPermissions } = useAuthPermissions();

  const [isAddressInUseAlertVisible, setAddressInUseAlertVisibility] = useState(false);
  const [isConfirmDeleteModalVisible, setConfirmDeleteModalVisibility] = useState(false);

  const isHistoryModalVisible = searchParams.get('showHistory') === 'true';

  const {
    data: address,
    isLoading,
    refetch: reloadAddressData,
  } = useQuery(
    ['address', addressId],
    () => {
      return getAddress(addressId);
    },
    {
      retry: false,
      // don't refetch while editing the form
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      // make sure that next time we enter the screen the data is re-fetched
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: 'always',
    },
  );

  const { mutateAsync: mutateAddress, isLoading: isUpdatingAddress } = useUpdateAddress(addressId);
  const { mutate: mutateAddressActiveState, isLoading: isUpdatingAddressActiveState } =
    useSetAddressActiveState(addressId);

  const handleSaveClick = async (newAddressInfo: Address) => {
    try {
      await mutateAddress(
        {
          payload: newAddressInfo,
        },
        {
          onSuccess: () => {
            navigate(-1);
          },
        },
      );
    } catch (error) {}
  };

  const handleViewAddressHistoryClick = () => {
    setQuery(
      {
        showHistory: 'true',
      },
      { replace: true },
    );
  };

  const handleHideAddressHistoryClick = () => {
    setQuery(
      {
        showHistory: 'false',
      },
      { replace: true },
    );
  };

  const setAddressActiveState = (newState: boolean) => {
    mutateAddressActiveState(
      {
        newState,
      },
      {
        onSuccess: () => {
          reloadAddressData();
        },
      },
    );
  };

  const handleDeleteAddressClick = () => {
    if (address?.inUse) {
      setAddressInUseAlertVisibility(true);

      return;
    }

    setConfirmDeleteModalVisibility(true);
  };

  const handleUndoDeleteAddressClick = () => {
    setAddressActiveState(true);
  };

  const defineHeaderButtons = () => {
    const buttons: HeaderButton[] = [];

    if (hasPermissions([PERMISSION.VIEW_ADDRESSES_HISTORY])) {
      buttons.push({
        title: 'היסטוריית חלוקה',
        icon: <TimelineIcon />,
        onclick: handleViewAddressHistoryClick,
      });
    }

    if (hasPermissions([PERMISSION.DELETE_ADDRESSES]) && address) {
      if (address.active) {
        buttons.push({
          title: 'מחיקת כתובת',
          icon: <DeleteIcon />,
          onclick: handleDeleteAddressClick,
        });
      } else {
        buttons.push({
          title: 'שחזור כתובת',
          icon: <RestoreIcon />,
          onclick: handleUndoDeleteAddressClick,
        });
      }
    }

    return buttons;
  };

  const renderAddressView = () => {
    if (!address) {
      return null;
    }

    return (
      <>
        {!address.active && <AddressNotActiveWarning />}
        <AddressForm
          address={address}
          onSaveClick={handleSaveClick}
          disableEditing={!address.active}
        />
      </>
    );
  };

  return (
    <>
      <Header
        screenTitle="עריכת כתובת"
        isLoading={isLoading || isUpdatingAddress || isUpdatingAddressActiveState}
        hasBack
        buttons={defineHeaderButtons()}
        maxVisibleButtons={1}
      />

      <div style={{ width: '95%', margin: '10px auto 0 auto', paddingBottom: 100 }}>
        {address && renderAddressView()}
      </div>

      {isHistoryModalVisible && (
        <AddressHistoryModal addressId={addressId} onClose={handleHideAddressHistoryClick} />
      )}

      <AlertModal
        title="הכתובת בשימוש"
        isVisible={isAddressInUseAlertVisible}
        onConfirm={() => {
          setAddressInUseAlertVisibility(false);
        }}
      >
        יש להסיר את השיוך של הכתובת מהמסלולים הקבועים לפני המחיקה
      </AlertModal>

      <ConfirmationModal
        isVisible={isConfirmDeleteModalVisible}
        title="מחיקת כתובת"
        onConfirm={() => {
          setAddressActiveState(false);
          setConfirmDeleteModalVisibility(false);
        }}
        onCancel={() => {
          setConfirmDeleteModalVisibility(false);
        }}
      >
        הכתובת תועבר לארכיון ולא יהיה ניתן לשייך אותה למסלולים.
        בטוח שברצונך להמשיך?
      </ConfirmationModal>
    </>
  );
};
