import { useMutation, useQuery } from "react-query";
import { AxiosError } from "axios";
import { getRoute, resetAllRoutesTeams, setRouteInfo, setRouteOrder } from "../api/routes";
import { CONFIG } from "../config";


export const useRoute = (routeId: string) => {
  return useQuery(['route', routeId], () => {
    return getRoute(routeId);
  }, {
    retry: false,
    refetchInterval: CONFIG.AUTO_REFETCH_INTERVAL,
  });
};

export const useSetRouteOrder = (
  routeId: string,
) => {
  return useMutation<
    void,
    AxiosError,
    {
      addresses: string[]
    }>(['route-order', routeId], async ({ addresses }) => {
      return setRouteOrder(routeId, addresses);
    });
};

export type SaveRoutePayload = {
  routeId: string;
  name: string;
  driver: string;
  passengers: string;
};

export const useSetRouteInfo = () => {
  return useMutation<
    void,
    AxiosError,
    SaveRoutePayload>(['route-info'], async ({ routeId, name, driver, passengers }) => {
      return setRouteInfo(routeId, name, driver, passengers);
    });
};

export const useResetAllRoutesTeams = () => {
  return useMutation<
    void,
    AxiosError,
    {
      distributionId: string
    }>(['reset-all-routes-teams'], async ({ distributionId }) => {
      return resetAllRoutesTeams(distributionId);
    });
};
