import { useState } from 'react';
import { Route } from '../../../@types/routes';
import { ActionButton } from '../../../components/action-button';
import { Modal } from '../../../components/modal';
import { SaveRoutePayload } from '../../../queries/route';
import { ButtonsRow, CustomInput, ErrorMessage } from './style';

type EditRouteModalProps = {
  route: Route;
  onSaveClick: (payload: SaveRoutePayload) => void;
  onCancel: () => void;
};

export const EditRouteModal = ({ route, onSaveClick, onCancel }: EditRouteModalProps) => {
  const [routeName, setRouteName] = useState<string>(route.name);
  const [routeDriver, setRouteDriver] = useState<string>(route.driver || '');
  const [routePassengers, setRoutePassengers] = useState<string>(route.passengers || '');

  const [errorMessage, setErrorMessage] = useState('');

  const handleRouteNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRouteName(event.currentTarget.value);
  };

  const handleRouteDriverChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRouteDriver(event.currentTarget.value);
  };

  const handleRoutePassengersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoutePassengers(event.currentTarget.value);
  };

  const handleSaveClick = () => {
    setErrorMessage('');

    if (!routeName) {
      setErrorMessage('שם המסלול לא יכול להיות ריק');

      return;
    }

    onSaveClick({
      routeId: route.id,
      name: routeName,
      driver: routeDriver,
      passengers: routePassengers,
    });
  };

  return (
    <Modal title="פרטי המסלול" isVisible onClose={onCancel}>
      <form
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleSaveClick();
        }}
      >
        <CustomInput label="שם המסלול" value={routeName} onChange={handleRouteNameChange} />
        <CustomInput label="שם הנהג" value={routeDriver} onChange={handleRouteDriverChange} />
        <CustomInput
          label="שמות הנוסעים"
          value={routePassengers}
          onChange={handleRoutePassengersChange}
        />

        {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

        <ButtonsRow>
          <ActionButton title="שמירה" type="submit" />
          <ActionButton title="ביטול" onClick={onCancel} kind="secondary" />
        </ButtonsRow>
      </form>
    </Modal>
  );
};
