import { Distribution } from '../../../../@types/distributions';
import { Checkbox } from '../../../../components/checkbox';
import { NotActiveBadge } from '../../../../components/not-active-badge/style';
import { Content, WatchDistributionButton, Wrapper, Title } from './style';

type EditableDistributionListItemProps = {
  distribution: Distribution;
  isSelected: boolean;
  onClick: () => void;
  onToggleSelect: () => void;
};

export const EditableDistributionListItem = ({
  distribution,
  isSelected,
  onClick,
  onToggleSelect,
}: EditableDistributionListItemProps) => {
  return (
    <Wrapper onClick={onToggleSelect}>
      <Checkbox isChecked={isSelected} />
      <Content>
        <Title>
          {distribution.name} {!distribution?.isActive && <NotActiveBadge>לא פעילה</NotActiveBadge>}
        </Title>
      </Content>
      <WatchDistributionButton
        onClick={(event) => {
          event.stopPropagation();
          onClick();
        }}
      >
        צפייה
      </WatchDistributionButton>
    </Wrapper>
  );
};
