import { useState } from 'react';
import { ActionButton } from '../../../components/action-button';
import { Modal } from '../../../components/modal';
import { useCreateRoute } from '../../../queries/routes';
import { DefaultRoute } from '../../../@types/default-routes';
import { useAddDefaultRoutesToDistribution } from '../../../queries/distributions';
import { ButtonsRow, CustomInput, ErrorMessage } from './style';
import { DefaultRoutesSelector } from './default-routes-selector';

enum ROUTE_SOURCE {
  NEW = 'NEW',
  DEFAULT_ROUTES = 'DEFAULT_ROUTES',
}

type AddRouteModalProps = {
  distributionId: string;
  onRouteAdded: () => void;
  onCancel: () => void;
};

export const AddRouteModal = ({ distributionId, onRouteAdded, onCancel }: AddRouteModalProps) => {
  const [selectedRouteSource, setSelectedRouteSource] = useState(ROUTE_SOURCE.NEW);

  const [routeName, setRouteName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');

  const [selectedDefaultRoutesIds, setSelectedDefaultRoutesIds] = useState<string[]>([]);

  const { mutate: mutateCreateRoute } = useCreateRoute();
  const { mutate: mutateAddDefaultRoutes } = useAddDefaultRoutesToDistribution();

  // --------------------------------------
  // NEW ROUTE
  // --------------------------------------

  const handleRouteNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRouteName(event.currentTarget.value);
  };

  const renderNewRouteView = () => {
    return (
      <>
        <CustomInput label="שם המסלול" value={routeName} onChange={handleRouteNameChange} />
      </>
    );
  };

  const handleAddNewRoute = () => {
    setErrorMessage('');
    const newRouteName = routeName.trim();

    if (!newRouteName) {
      setErrorMessage('שם המסלול לא יכול להיות ריק');

      return;
    }

    mutateCreateRoute(
      { distributionId, routeName: newRouteName },
      {
        onSuccess: () => {
          onRouteAdded();
        },
      },
    );
  };

  // --------------------------------------
  // DEFAULT ROUTES
  // --------------------------------------

  const handleAddDefaultRoutes = () => {
    setErrorMessage('');

    if (selectedDefaultRoutesIds.length <= 0) {
      setErrorMessage('יש לבחור לפחות מסלול אחד');

      return;
    }

    mutateAddDefaultRoutes(
      {
        distributionId,
        defaultRoutesIds: selectedDefaultRoutesIds,
      },
      {
        onSuccess: () => {
          onRouteAdded();
        },
      },
    );
  };

  const toggleDefaultRouteSelect = (route: DefaultRoute) => {
    const newSelectedDefaultRoutes = [...selectedDefaultRoutesIds];

    const itemIndex = newSelectedDefaultRoutes.findIndex((selectedItem) => {
      return selectedItem === route.id;
    });

    if (itemIndex >= 0) {
      newSelectedDefaultRoutes.splice(itemIndex, 1);
    } else {
      newSelectedDefaultRoutes.push(route.id);
    }

    setSelectedDefaultRoutesIds(newSelectedDefaultRoutes);
  };

  // --------------------------------------
  // ROUTE SOURCE
  // --------------------------------------

  const handleRouteSourceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRouteSource(event.currentTarget.value as ROUTE_SOURCE);

    setErrorMessage('');
  };

  const handleSaveClick = () => {
    switch (selectedRouteSource) {
      case ROUTE_SOURCE.NEW: {
        handleAddNewRoute();
        break;
      }
      case ROUTE_SOURCE.DEFAULT_ROUTES: {
        handleAddDefaultRoutes();
        break;
      }
    }
  };

  const renderRouteSourceView = () => {
    switch (selectedRouteSource) {
      case ROUTE_SOURCE.NEW: {
        return renderNewRouteView();
      }
      case ROUTE_SOURCE.DEFAULT_ROUTES: {
        return (
          <DefaultRoutesSelector
            selectedIds={selectedDefaultRoutesIds}
            onDefaultRouteSelect={toggleDefaultRouteSelect}
          />
        );
      }
    }
  };

  return (
    <Modal title="הוספת מסלולים" isVisible onClose={onCancel}>
      <form
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleSaveClick();
        }}
      >
        <select
          onChange={handleRouteSourceChange}
          value={selectedRouteSource}
          style={{ marginBottom: 10 }}
        >
          <option value={ROUTE_SOURCE.NEW}>מסלול חדש</option>
          <option value={ROUTE_SOURCE.DEFAULT_ROUTES}>מתוך המסלולים הקבועים</option>
        </select>

        {renderRouteSourceView()}

        {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

        <ButtonsRow>
          <ActionButton title="שמירה" type="submit" />
          <ActionButton title="ביטול" onClick={onCancel} kind="secondary" />
        </ButtonsRow>
      </form>
    </Modal>
  );
};
