import { useState } from 'react';
import { ActionButton } from '../action-button';
import { Modal } from '../modal';
import { ButtonsRow, CustomInput, ErrorMessage } from './style';

type PromptModalProps = {
  title: string;
  inputLabel?: string;
  defaultValue?: string;
  onSaveClick: (value: string) => void;
  onCancel: () => void;
  saveButtonTitle?: string;
  cancelButtonTitle?: string;
};

export const PromptModal = ({
  title,
  inputLabel,
  defaultValue = '',
  onSaveClick,
  onCancel,
  saveButtonTitle = 'שמירה',
  cancelButtonTitle = 'ביטול',
}: PromptModalProps) => {
  const [value, setValue] = useState<string>(defaultValue);

  const [errorMessage, setErrorMessage] = useState('');

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  };

  const handleSaveClick = () => {
    setErrorMessage('');

    if (!value) {
      setErrorMessage('יש להזין ערך');

      return;
    }

    onSaveClick(value);
  };

  return (
    <Modal title={title} isVisible onClose={onCancel}>
      <form
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleSaveClick();
        }}
      >
        <CustomInput label={inputLabel} value={value} onChange={handleValueChange} />

        {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

        <ButtonsRow>
          <ActionButton title={saveButtonTitle} type="submit" />
          <ActionButton title={cancelButtonTitle} onClick={onCancel} kind="secondary" />
        </ButtonsRow>
      </form>
    </Modal>
  );
};
