import { APARTMENT_STATUS, Address, AddressHistory, ExtendedAddress } from "../../@types/address";
import { DAY } from "../../consts/time";
import { timeDiff } from "../../utils/time";

export type AddressHistoryRecordResponse = {
  distributionId: string,
  distributionName: string,
  date: string,
  inDistribution: boolean,
  status: string,
  wasPackageLeft: boolean,
}

export type AddressHistoryResponse = {
  records: AddressHistoryRecordResponse[]
}

export type AddressResponse = {
  id: string,
  createdAt: string,
  active: boolean,
  city: string,
  street: string,
  buildingNumber: number,
  entrance: string,
  addressInstructions: string,
  code: string,
  floor: number,
  apartmentNumber: number,
  apartmentNotes: string,
  familyName: string,
  familyPhoneNumber: string,
  generalNotes: string,
  internalNotes: string,
  contactName: string,
  contactPhoneNumber: string,
  contactTitle: string,
  location: {
    latitude: number,
    longitude: number,
  },
  totalPackages: number,
  oneTime: string,
  medias: [],
  routeOrder?: number,
  status?: string | null,
  wasPackageLeft?: boolean,
}

type GetAllAddressesResponse = {
  addresses: AddressResponse[];
}

type GetAllAddresses = {
  addresses: Address[];
}

export type ExtendedAddressResponse = AddressResponse & {
  routes: string // todo: in the future, return ids / objects
}

type GetAllExtendedAddressesResponse = {
  addresses: ExtendedAddressResponse[];
}

type GetAllExtendedAddresses = {
  addresses: ExtendedAddress[]
}

export const isNewAddress = (createTime: Date) => {
  return timeDiff(new Date(), createTime) < 20 * DAY;
};

export const formatAddressHistory = (response: AddressHistoryResponse): AddressHistory => {
  return {
    records: response.records.map((record) => {
      return {
        ...record,
        date: new Date(record.date),
        status: record.status as APARTMENT_STATUS,
      };
    }),
  };
};

export const formatAddress = (response: AddressResponse): Address => {
  const createdAt = new Date(response.createdAt);

  return {
    ...response,
    createdAt,
    wasPackageLeft: response.wasPackageLeft ?? false,
    status: response.status as APARTMENT_STATUS,
    _isNewAddress: isNewAddress(createdAt),
  };
};

export const formatAddresses = (response: AddressResponse[]): Address[] => {
  return response.map(formatAddress);
};

export const formatAllExtendedAddresses =
  (response: GetAllExtendedAddressesResponse): GetAllExtendedAddresses => {
    return {
      addresses: response.addresses.map((address) => {
        const createdAt = new Date(address.createdAt);

        return {
          ...address,
          createdAt,
          wasPackageLeft: address.wasPackageLeft ?? false,
          status: address.status as APARTMENT_STATUS,
          _isNewAddress: isNewAddress(createdAt),
        };
      }),
    };
  };

export const formatAllAddresses =
  (response: GetAllAddressesResponse): GetAllAddresses => {
    return {
      addresses: response.addresses.map((address) => {
        const createdAt = new Date(address.createdAt);

        return {
          ...address,
          createdAt,
          status: null,
          wasPackageLeft: null,
          _isNewAddress: isNewAddress(createdAt),
        };
      }),
    };
  };
