import { Route } from "../@types/routes";
import { formatGetAllDistributions } from "./formatters/distributions";
import { axiosInstance } from ".";

export type GetDistributionResponse = {
  name: string,
  isActive: boolean,
  routes: Route[],
}

export type CreateDistributionPayload = {
  name: string,
  duplicateFrom?: string | null
}

export const getAllDistributions = async () => {
  const result = await axiosInstance.get(`/get-distributions.php`);

  return formatGetAllDistributions(result.data);
};

export const getDistribution = async (distributionId: string) => {
  const result = await axiosInstance.get(`/get-distribution.php?distribution=${distributionId}`);

  return result.data as GetDistributionResponse;
};

export const createDistribution = async (data: CreateDistributionPayload) => {
  await axiosInstance.post(`/create-distribution.php`, data);
};

export const setDistributionsActiveState = async (
  distributionsIds: string[],
  newState: boolean,
) => {
  await axiosInstance.post('/set-active-distributions.php', {
    distributionsList: distributionsIds,
    newState,
  });
};

export const addDefaultRoutesToDistribution = async (
  distributionId: string,
  defaultRoutesIds: string[],
) => {
  await axiosInstance.post('/add-default-routes-to-distribution.php', {
    distributionId,
    defaultRoutesIds,
  });
};
