import { User } from '../../../../@types/users';
// import { LeftArrowIcon } from '../../../../assets/svgs';
// import { COLORS } from '../../../../consts/colors';
import { formatDateTime } from '../../../../utils/time';
import {
  Content,
  Wrapper,
  TitleRow,
  StatusIcon,
  RoleBadge,
  LastActivity,
  DateTimeValue,
} from './style';

type UserListItemProps = {
  user: User;
  onClick: () => void;
};

export const UserListItem = ({ user, onClick }: UserListItemProps) => {
  const isOnline = () => {
    const lastActivitySeconds = user.lastActivityTime ? user.lastActivityTime.getTime() : 0;
    const nowSeconds = new Date().getTime();

    return lastActivitySeconds >= nowSeconds - 30 * 1000;
  };

  return (
    <Wrapper onClick={onClick}>
      <StatusIcon isOnline={isOnline()} />
      <Content>
        <TitleRow>
          {user.firstName} {user.lastName}
          <RoleBadge>{user.role.name}</RoleBadge>
        </TitleRow>
        <LastActivity>
          פעילות אחרונה:
          <DateTimeValue>
            {user.lastActivityTime ? formatDateTime(user.lastActivityTime) : 'אף פעם'}
          </DateTimeValue>
        </LastActivity>
      </Content>
      {/* <LeftArrowIcon style={{ fill: COLORS.DARKER_GRAY }} width={20} height={20} /> */}
    </Wrapper>
  );
};
