import styled from 'styled-components';
import { COLORS } from '../../consts/colors';

export type ActionButtonType = 'primary' | 'secondary';

export const Button = styled.button<{
  buttonType: ActionButtonType;
}>`
  background-color: ${(props) =>
    props.buttonType === 'primary' ? COLORS.DARK_BLUE : COLORS.LIGHT_BLUE};
  color: white;
  border: 0;
  box-shadow: none;
  padding: 20px;
  width: 100%;
  cursor: pointer;
  font-family: 'NextExitRound', Arial;
  font-size: 26px;
  line-height: 22px;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;
