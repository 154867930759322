import { Address } from "../../@types/address";
import { Route } from "../../@types/routes";
import { AddressResponse, formatAddresses } from "./addresses";

export type GetRouteServerResponse = {
  addresses: AddressResponse[];
  id: string;
  name: string;
  driver: string;
  passengers: string;
  isActive: boolean;
}

type GetRouteResponse = Route & {
  addresses: Address[];
}

export const formatRoute = (response: GetRouteServerResponse): GetRouteResponse => {
  return {
    ...response,
    addresses: formatAddresses(response.addresses),
  };
};
