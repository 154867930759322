import { DetailsWrapper, Title, Value } from './style';

type AddressDetailProps = {
  title: string;
  children?: React.ReactNode;
  isLTR?: boolean;
};

export const AddressDetail = ({ title, children, isLTR }: AddressDetailProps) => {
  if (typeof children === 'undefined' || children === null) {
    return null;
  }

  return (
    <DetailsWrapper>
      <Title>{title}</Title>
      <Value isLTR={isLTR}>{children}</Value>
    </DetailsWrapper>
  );
};
