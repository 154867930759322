import { ExtendedAddress } from '../../../@types/address';
import { PERMISSION } from '../../../consts/permissions';
import { SCREENS } from '../../../consts/screens';
import { useAuthPermissions } from '../../../hooks/auth';
import { AddressListItem } from './address-list-item';
import { NewAddressLink } from './style';

type AddressesListProps = {
  addresses: ExtendedAddress[];
  selectedIds: string[];
  onAddressClick: (address: ExtendedAddress) => void;
};

export const AddressesList = ({ addresses, selectedIds, onAddressClick }: AddressesListProps) => {
  const { hasPermissions } = useAuthPermissions();

  if (addresses.length <= 0) {
    return (
      <div>
        אין כתובות להצגה.
        {hasPermissions([PERMISSION.CREATE_ADDRESSES]) && (
          <NewAddressLink to={SCREENS.CREATE_ADDRESS}>הוספת כתובת חדשה</NewAddressLink>
        )}
      </div>
    );
  }

  return (
    <>
      {addresses.map((address) => {
        return (
          <AddressListItem
            key={address.id}
            address={address}
            isSelected={selectedIds.includes(address.id)}
            onClick={() => {
              onAddressClick(address);
            }}
          />
        );
      })}
    </>
  );
};
