import { Distribution } from '../../../@types/distributions';
import { DistributionListItem } from './distribution-list-item';

type DistributionsListProps = {
  distributions: Distribution[];
  onDistributionClick: (distribution: Distribution) => void;
};

export const DistributionsList = ({
  distributions,
  onDistributionClick,
}: DistributionsListProps) => {
  if (distributions.length <= 0) {
    return <div>אין חלוקות פעילות</div>;
  }

  return (
    <>
      {distributions.map((distribution) => {
        return (
          <DistributionListItem
            onClick={() => {
              onDistributionClick(distribution);
            }}
            key={distribution.id}
            distribution={distribution}
          />
        );
      })}
    </>
  );
};
